import { gql } from "@apollo/client";

export const GET_ADMIN_LIST = gql`
  query ($input: GetAdminListDTO!) {
    getAdminList(input: $input) {
      message
      adminList {
        _id
        name
        email
        phone
        status
        role
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_ADMIN = gql`
  query ($id: String!) {
    getAdmin(id: $id) {
      message
      admin {
        _id
        name
        email
        role
        status
        displayImage
        phone
        hasFinanacialAccess
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query {
    getUserProfile {
      _id
      name
      email
      phone
      status
      role
      displayImage
      hasFinanacialAccess
    }
  }
`;
