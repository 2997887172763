import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($input: LoginAdminDTO!) {
    loginAsAnAdmin(input: $input) {
      admin {
        _id
        name
        email
        phone
        role
        status
      }
      refreshToken
      accessToken
    }
  }
`;

export const REGISTER = gql`
  mutation ($input: CreateAdminDTO!) {
    register(input: $input) {
      _id
      name
      email
      status
      role
      phone
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($input: String!) {
    forgotPassword(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordDTO!) {
    resetPasswordAdmin(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($input: ChangePasswordDTO!) {
    changePassword(input: $input) {
      message
      status
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGOUT = gql`
  mutation logOutAsAnAdmin {
    logOutAsAnAdmin {
      message
      success
    }
  }
`;

export const CHECK_VALIDITY_OF_LINK = gql`
  mutation checkValidityOfLink($token: String!) {
    checkValidityOfLink(token: $token) {
      message
      success
    }
  }
`;
