import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      dynamicLink
      _id
    }
  }
`;
