import React from "react";

import useGQL from "hooks/useGQL";
import { ADMIN_ROLE } from "store/constant";

const SuperAdminAccesMenusGuard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { GET_ADMIN_PROFILE } = useGQL();
  const { data } = GET_ADMIN_PROFILE();

  if (data && data?.getUserProfile?.role !== ADMIN_ROLE.SUPER_ADMIN) {
    return null;
  }

  return <>{children}</>;
};

export default SuperAdminAccesMenusGuard;
