import Spinner from "components/spinner";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import { useDialog } from "views/userManagement/admins/hooks/useDialogs";
import { NotificationsForAdmin } from "./constants/types";
//ICONS

import TableErrorMessage from "components/table/TableErrorMessage";
import { RootState, useSelector } from "store";
import { useLazyQuery } from "@apollo/client";
import NotificationTable from "./table/NotificationTable";
import { GET_ALL_NOTIFICATION_FOR_ADMIN } from "grapqhl/adminNotification/query";
import CustomizedBreadcrumb from "components/custom-breadcrumb";
import { setLimit, setPage } from "store/slices/table";
import CustomPagination from "components/pagination/CustomPagintion";

const center = {
  textAlign: "center",
  direction: "row",
  justifyContent: "center",
};

const NotificationList = () => {
  const { page } = useSelector((state: RootState) => state.table);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({
    limit: 10,
    skip: 0,
  });

  /* queries & mutations */

  const { sort } = useSelector((state: RootState) => state.table);

  /* local states */
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([]);

  const [notifications, setNotifications] = useState<NotificationsForAdmin[]>(
    []
  );
  const { handleDialogOpen } = useDialog(); //TODO: Change this to global component

  const [listAllNotifications, { error, loading, refetch }] = useLazyQuery(
    GET_ALL_NOTIFICATION_FOR_ADMIN,
    {
      onCompleted(data) {
        if (data?.getNotificationsForAdmin) {
          setNotifications(data.getNotificationsForAdmin?.data);
          setCount(data.getNotificationsForAdmin?.pagination?.total);
        }
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const limit = rowsPerPage;
    let skip = page > 1 ? rowsPerPage * (page - 1) : 0;

    setPageMeta({ limit, skip });
    listAllNotifications({
      variables: {
        input: {
          searchText: search,
          limit,
          skip,
          order: sort.order,
          orderBy: sort.orderBy,
        },
      },
    });
  }, [
    listAllNotifications,
    rowsPerPage,
    page,
    search,
    sort.order,
    sort.orderBy,
    pageMeta.limit,
    pageMeta.skip,
  ]);

  const handleRefetch = () => {
    refetch({
      input: {
        searchText: search,
        limit: pageMeta?.limit,
        skip: pageMeta?.skip,
        order: sort.order,
        orderBy: sort.orderBy,
      },
    });
  };

  useEffect(() => {
    setPage(0);
    handleRefetch();
  }, [rowsPerPage]);

  const handleEdit = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    id: string
  ) => {
    const selection = notifications.find((admin) => admin._id === id);
    if (selection) {
      handleDialogOpen();
    }
  };

  const handleRowPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  return (
    <>
      <CustomizedBreadcrumb title="Notifications" />
      <MainCard
        title=""
        content={false}
        sx={{ py: { md: 1 }, px: { xs: 2, md: 3 } }}
      >
        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <NotificationTable
              {...{
                selected,
                setSelected,
                setIsSelected,
                notifications,
                handleEdit,
                handleRefetch,
              }}
            />
            {error && (
              <TableErrorMessage
                className={center}
                colSpan={8}
                message={error.message}
              />
            )}
            {!loading && !error && !notifications.length && (
              <TableErrorMessage
                className={center}
                colSpan={2}
                message="No Data Found"
              />
            )}

            <CustomPagination
              totalPages={Math.ceil(count / rowsPerPage)}
              setPage={setPage}
              setLimit={setLimit}
              currentPage={page}
              itemCount={count}
              rowsPerPage={rowsPerPage}
              handleRowPerPage={handleRowPerPage}
              defaultRows={10}
              rowLabel="Rows"
            />
          </>
        )}
      </MainCard>
    </>
  );
};

export default NotificationList;
