export interface IPricingOption {
  salePost: number | undefined;
  rentPost: number | undefined;
  holidayLettings?: number;
}

export enum SubscriptionBillingCycle {
  MONTHLY = "MONTH",
  QUARTERLY = "QUARTER",
  WEEKLY = "WEEK",
  YEARLY = "YEAR",
}

export interface ISubscription {
  agencyId: string;

  commencementDate: Date;

  billingCycle: SubscriptionBillingCycle;

  residentialPricing: IPricingOption;

  commercialPricing: IPricingOption;

  relistingPricing: IPricingOption;

  autoCharge: boolean | string;

  fee: number | undefined;
}
