import React from "react";

import useGQL from "hooks/useGQL";
import { ADMIN_ROLE } from "store/constant";

const FinancialAccesMenusGuard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { GET_ADMIN_PROFILE } = useGQL();
  const { data } = GET_ADMIN_PROFILE();

  if (
    data?.getUserProfile?.role === ADMIN_ROLE.ADMIN &&
    !data?.getUserProfile?.hasFinanacialAccess
  ) {
    return null;
  }

  return <>{children}</>;
};

export default FinancialAccesMenusGuard;
