import { gql } from "@apollo/client";

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscriptionForAdmin(
    $input: CancelSubscriptionForAdminInputDTO!
  ) {
    cancelSubscriptionForAdmin(input: $input)
  }
`;

export const RESTORE_SUBSCRIPTION = gql`
  mutation restoreSubscriptionForAdmin($agencyId: String!) {
    restoreSubscriptionForAdmin(agencyId: $agencyId) {
      message
    }
  }
`;
