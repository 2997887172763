import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useDebounce } from "use-debounce";
import React from "react";

const Search = ({
  search,
  handleSearch,
  handleDebounce,
}: {
  search: string;
  handleDebounce: (text: string) => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [value] = useDebounce(search, 300);

  handleDebounce(value);

  return (
    <TextField
      variant="filled"
      fullWidth
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: "1rem" }} />
          </InputAdornment>
        ),
      }}
      onChange={handleSearch}
      placeholder="Search"
      size="small"
    />
  );
};

export default Search;
