import { gql } from "@apollo/client";

export const UPDATE_CMS_PAGE = gql`
  mutation ($input: PageUpdateInput!) {
    updatePageDetails(input: $input) {
      message
      success
    }
  }
`;
