import { gql } from "@apollo/client";

export const GET_REPORT_LIST = gql`
  query ($input: GetListDTO!) {
    reportList(input: $input) {
      data {
        _id
        userId
        firstName
        lastName
        reason
        subject
        email
        createdAt
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const REPORTS_DETAILS = gql`
  query ($input: ReportDetailsDTO!) {
    reportViewDetails(input: $input) {
      _id
      userId
      firstName
      lastName
      reason
      subject
      email
      createdAt
    }
  }
`;
