import { gql } from '@apollo/client';

export const CREATE_MENU = gql`
    mutation ($input: CreateMenuDTO!) {
        createMenu(input: $input) {
            message
            menu {
                _id
                title
                logo
                imageAltText
                status
                menuPosition
            }
        }
    }
`;

export const DELETE_MENU = gql`
    mutation ($id: String!) {
        deleteMenu(id: $id) {
            message
        }
    }
`;

export const UPDATE_MENU = gql`
    mutation ($id: String!, $input: CreateMenuDTO!) {
        updateMenu(id: $id, input: $input) {
            message
            menu {
                _id
                title
                logo
                imageAltText
                status
                menuPosition
            }
        }
    }
`;
