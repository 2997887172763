import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useGQL from "hooks/useGQL";
import { ADMIN_ROLE } from "store/constant";

const FinancialAccessGuard = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const { GET_ADMIN_PROFILE } = useGQL();
  const { data } = GET_ADMIN_PROFILE();

  useEffect(() => {
    if (
      data?.getUserProfile?.role === ADMIN_ROLE.ADMIN &&
      !data?.getUserProfile?.hasFinanacialAccess
    ) {
      navigate("/login", { replace: true });
    }
  }, [
    data?.getUserProfile?.hasFinanacialAccess,
    navigate,
    data?.getUserProfile?.role,
  ]);

  return <>{children}</>;
};

export default FinancialAccessGuard;
