import { gql } from "@apollo/client";

export const UPLOAD_AWS = gql`
  mutation ($file: Upload!, $prefix: String!) {
    uploadToAws(file: $file, prefix: $prefix) {
      data {
        fileKey
      }
    }
  }
`;

export const UPDATE_AGENT_IMAGE = gql`
  mutation ($input: UpdateagentImageDTO!) {
    updateAgentImage(input: $input) {
      message
      success
    }
  }
`;

export const UPDATE_AGENT_LOGO_COVER_IMAGE = gql`
  mutation ($input: AgentLogoCoverImageInput!) {
    updateLogoCoverImageAgency(input: $input) {
      message
      success
    }
  }
`;
