import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION_DETAIL = gql`
  query getSubscriptionDetailForAdmin($agencyId: String!) {
    getSubscriptionDetailForAdmin(agencyId: $agencyId) {
      subscriptionSetting {
        _id
        agencyId
        commencementDate
        residentialPricing {
          salePost
          rentPost
        }
        commercialPricing {
          salePost
          rentPost
        }
        relistingPricing {
          salePost
          rentPost
          holidayLettings
        }
        fee
        status
        cancel_at
      }
    }
  }
`;

export const LIST_SUBSCRIPTION = gql`
  query ($input: GetSubscriptionsInputDTO!) {
    getSubscriptions(input: $input) {
      subscriptions {
        _id
        startDate
        agencyName
        agencyOwner
        status
        plan
        agencyId
      }

      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_SUBSCRIPTION_INFORMATION = gql`
  query getSubscriptionInfoAdmin($agencyId: String!) {
    getSubscriptionInfoAdmin(agencyId: $agencyId) {
      subscription {
        _id
        startDate
        endDate
        amount
        cancelDate
      }
    }
  }
`;

export const GET_SUB_PROPERTY_DUE = gql`
  query getSubPropertyCardInfoForAdmin($agencyId: String!) {
    getSubPropertyCardInfoForAdmin(agencyId: $agencyId) {
      subscription {
        dueAmount
        invoiceDate
      }
      card {
        isExpired
        paymentMethod
      }
      propertyCharge {
        dueAmount
      }
    }
  }
`;
