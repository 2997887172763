import React, { JSXElementConstructor, ReactElement, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { GET_THUMBNAIL_SIGNED_URL } from "grapqhl/image/queries";

const ThumbNailImageComponent = ({
  children,
  awsKey,
  size,
}: {
  children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  awsKey: string | undefined;
  size: string;
}) => {
  children = React.Children.only(children);

  const [getSignedThumbnailUrl, { loading, data }] = useLazyQuery(
    GET_THUMBNAIL_SIGNED_URL
  );

  useEffect(() => {
    if (awsKey) {
      getSignedThumbnailUrl({
        variables: {
          input: {
            key: awsKey,
            size,
          },
        },
      });
    }
  }, [awsKey, getSignedThumbnailUrl, size]);

  if (!loading && data?.getSignedThumbnailUrl?.url) {
    console.log("data", data?.getSignedThumbnailUrl?.url);

    const newElement = React.cloneElement(children, {
      src: data.getSignedThumbnailUrl.url,
    } as any);

    return newElement;
  }

  return React.cloneElement(children, {});
};

export default ThumbNailImageComponent;
