import { gql } from "@apollo/client";

export const GET_CONTACT_US_LIST = gql`
  query ($input: GetListDTO!) {
    contactUsList(input: $input) {
      data {
        _id
        userId
        firstName
        lastName
        message
        email
        createdAt
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const CONTACT_US_DETAILS = gql`
  query ($input: ViewContactUsDetailsDTO!) {
    contactUsViewDetails(input: $input) {
      _id
      userId
      firstName
      lastName
      email
      createdAt
      message
    }
  }
`;
