import { gql } from "@apollo/client";

export const UPDATE_REVIEW_SETTINGS_DETAIL = gql`
  mutation ($input: ReviewSettingsInput!) {
    updateReviewSettings(input: $input) {
      message
      success
    }
  }
`;
