import { gql } from '@apollo/client';

export const MARK_AS_READ = gql`
  mutation UpdateNotifications($updateNotificationsId: String!) {
    updateNotifications(id: $updateNotificationsId) {
      message
    }
  }
`;

export const MARK_ALL_READ = gql`
  mutation UpdateAllNotifications {
    updateAllNotifications {
      message
    }
  }
`;
