import { Grid, TableCell } from "@mui/material";

const TableErrorMessage = ({
  colSpan = 8,
  message,
  className,
}: {
  colSpan: any;
  message: any;
  className: any;
}) => (
  <Grid container justifyContent="center">
    <TableCell align="center" colSpan={colSpan} sx={className}>
      {message}
    </TableCell>
  </Grid>
);

export default TableErrorMessage;
