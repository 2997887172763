import { gql } from "@apollo/client";

export const ADD_AGENCY = gql`
  mutation ($input: AddAgencyInput!) {
    addAgency(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isSubscribed
        hasAgencyAccess
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_AGENCY = gql`
  mutation ($input: SubscriptionInput!) {
    updateAgencySubscription(input: $input) {
      message
      success
      data
    }
  }
`;

export const UPDATE_STATUS_AND_PASSWORD = gql`
  mutation ($input: AgencyPasswordAndStatus!) {
    updateAgencyPasswordAndStatus(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isSubscribed
      }
    }
  }
`;

export const UPDATE_AGENCY_DETAILS = gql`
  mutation ($input: UpdateAgencyInput!) {
    updateAgency(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isSubscribed
        hasAgencyAccess
      }
    }
  }
`;

export const UPDATE_AGENCY_STATUS_BULK = gql`
  mutation ($input: AgencyStatusBulkUpdate!) {
    updateAgencyStatusBulk(input: $input) {
      message
      success
    }
  }
`;
