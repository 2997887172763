import { Link, Typography, Stack, Grid } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import dayjs from "dayjs";

const CustomizedBreadcrumb = ({
  previousLink,
  title,
  link,
  userName,
  secondLink,
  secondTitle,
  createdAt
}: {
  previousLink?: string;
  title?: string;
  link?: string;
  userName?: string;
  secondLink?: string;
  secondTitle?: string;
  createdAt?: Date | null;
}) => {
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<KeyboardArrowRightIcon fontSize="small" />}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} sm={8}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Link underline="none" href={"/dashboard"}>
              <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                Home
              </Typography>
            </Link>
            <KeyboardArrowRightIcon color="secondary" />
            {link && previousLink && (
              <>
                <Link underline="none" href={link}>
                  <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                    {previousLink}
                  </Typography>
                </Link>
                <KeyboardArrowRightIcon color="secondary" />
              </>
            )}

            {secondLink && (
              <>
                <Link underline="none" href={secondLink}>
                  <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                    {secondTitle}
                  </Typography>
                </Link>
                <KeyboardArrowRightIcon color="secondary" />
              </>
            )}

            {title && (
              <Typography
                color="primary"
                variant="caption"
                sx={{ whiteSpace: "nowrap" }}
              >
                {title}
              </Typography>
            )}

            {userName && (
              <Typography
                color="primary"
                variant="caption"
                sx={{ whiteSpace: "nowrap" }}
              >
                {userName}
              </Typography>
            )}
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent={{ xs: "flex-start", sm: "flex-end" }}
          sx={{ pl: "0.75rem", pr: "1rem" }}
        >
          {createdAt ? (
            <Typography variant="caption" color="text.hint">
              {createdAt && dayjs(createdAt).format("DD MMMM YYYY hh:mm A")}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </MuiBreadcrumbs>
  );
};

export default CustomizedBreadcrumb;
