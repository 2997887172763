// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Avatar, List, ListItem, Stack, Typography, Box } from "@mui/material";

// assets
import {
  IconBrandTelegram,
  IconBuildingStore,
  IconMailbox,
  IconPhoto,
} from "@tabler/icons";
import User1 from "assets/images/users/user-round.svg";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageComponent from "views/NotificationList/image";
import { fontSize, fontWeight, lineHeight } from "@mui/system";
import ThumbNailImageComponent from "components/image/ThumbNail";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  //padding: "0 16px 0 16px",
  //margin: "1px 0px 1px 0px",
  //borderRadius: "6px",
  // "&:hover": {
  //   background:
  //     theme.palette.mode === "dark"
  //       ? theme.palette.dark.main
  //       : theme.palette.primary.light
  // },
  "& .MuiListItem-root": {
    borderRadius: "6px",
    padding: "6px 16px 11px!important",
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.dark.main
          : theme.palette.primary.light,
    },
  },
  "& .MuiListItemAvatar-root": {
    minWidth: "30px",
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({
  notifications,
  onClose,
  handleRefetch,
  onRead,
  markAsReadHandler,
}) => {
  const theme = useTheme();
  const [notificationId, setNotificationId] = useState<string | null>();
  const navigate = useNavigate();

  const chipSX = {
    height: 24,
    padding: "0 6px",
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.orange.light,
    marginRight: "5px",
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.warning.light,
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.success.light,
    height: 28,
  };

  const handleRead = async (e, n) => {
    setNotificationId(n._id);

    // if (n.type === 'event-fully-booked') {
    //   navigate('/events?eventId=' + n.eventId);
    // }

    navigate("properties/" + n?.propertyId);

    if (!n.isRead) {
      await onRead(n._id);
      markAsReadHandler();
      await handleRefetch();
    }

    onClose(e);
  };

  return (
    <List
      sx={{
        //width: "100%",
        //maxWidth: 361,
        py: 0,
        borderRadius: "12px",
        // [theme.breakpoints.down("md")]: {
        //   maxWidth: 300
        // },
        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
        "& .list-container": {
          pl: 4,
        },
        "& .unread": {
          background: "#fbe9e7",
          //mb: "3px!important"
        },
      }}
    >
      {notifications?.map((n) => (
        <>
          <ListItemWrapper
            onClick={(e) => handleRead(e, n)}
            sx={{
              borderBottom: "1px solid #f5f5f5",
              marginBottom: "2px",
            }}
          >
            <ListItem
              alignItems="center"
              className={n.isRead ? "" : "unread"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
                mb: "2px",
                mt: "2px",
              }}
            >
              {n?.agentOrAgencyPhoto ? (
                <ThumbNailImageComponent
                  awsKey={n?.agentOrAgencyPhoto}
                  size="84x84"
                >
                  <Avatar sx={{ width: "32px", height: "32px" }} />
                </ThumbNailImageComponent>
              ) : (
                <Avatar sx={{ width: "32px", height: "32px" }} />
              )}
              <Stack direction="column" spacing={-0.25}>
                <Typography
                  variant="caption"
                  color="primary"
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {n?.agentOrAgencyName}
                </Typography>
                <Typography variant="subtitle1">
                  {n?.activityText === "Property added"
                    ? "Created a new property"
                    : n?.activityText}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    color: "#757575",
                  }}
                  display="block"
                >
                  {moment(n.date).fromNow()}
                </Typography>
              </Stack>
            </ListItem>
          </ListItemWrapper>
        </>
      ))}
    </List>
  );
};

export default NotificationList;

{
  /* <ListItemWrapper>
<ListItem alignItems="center">
  <ListItemAvatar>
    <Avatar alt="John Doe" src={User1} />
  </ListItemAvatar>
  <ListItemText primary="John Doe" />
  <ListItemSecondaryAction>
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <Typography variant="caption" display="block" gutterBottom>
          2 min ago
        </Typography>
      </Grid>
    </Grid>
  </ListItemSecondaryAction>
</ListItem>
<Grid container direction="column" className="list-container">
  <Grid item xs={12} sx={{ pb: 2 }}>
    <Typography variant="subtitle2">
      It is a long established fact that a reader will be distracted
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Grid container>
      <Grid item>
        <Chip label="Unread" sx={chipErrorSX} />
      </Grid>
      <Grid item>
        <Chip label="New" sx={chipWarningSX} />
      </Grid>
    </Grid>
  </Grid>
</Grid>
</ListItemWrapper>
<Divider />
<ListItemWrapper>
<ListItem alignItems="center">
  <ListItemAvatar>
    <Avatar
      sx={{
        color: theme.palette.success.dark,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.dark.main
            : theme.palette.success.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.success.main,
      }}
    >
      <IconBuildingStore stroke={1.5} size="1.3rem" />
    </Avatar>
  </ListItemAvatar>
  <ListItemText
    primary={
      <Typography variant="subtitle1">
        Store Verification Done
      </Typography>
    }
  />
  <ListItemSecondaryAction>
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <Typography variant="caption" display="block" gutterBottom>
          2 min ago
        </Typography>
      </Grid>
    </Grid>
  </ListItemSecondaryAction>
</ListItem>
<Grid container direction="column" className="list-container">
  <Grid item xs={12} sx={{ pb: 2 }}>
    <Typography variant="subtitle2">
      We have successfully received your request.
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Grid container>
      <Grid item>
        <Chip label="Unread" sx={chipErrorSX} />
      </Grid>
    </Grid>
  </Grid>
</Grid>
</ListItemWrapper>
<Divider />
<ListItemWrapper>
<ListItem alignItems="center">
  <ListItemAvatar>
    <Avatar
      sx={{
        color: theme.palette.primary.dark,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.dark.main
            : theme.palette.primary.light,
        border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
        borderColor: theme.palette.primary.main,
      }}
    >
      <IconMailbox stroke={1.5} size="1.3rem" />
    </Avatar>
  </ListItemAvatar>
  <ListItemText
    primary={
      <Typography variant="subtitle1">Check Your Mail.</Typography>
    }
  />
  <ListItemSecondaryAction>
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Typography variant="caption" display="block" gutterBottom>
          2 min ago
        </Typography>
      </Grid>
    </Grid>
  </ListItemSecondaryAction>
</ListItem>
<Grid container direction="column" className="list-container">
  <Grid item xs={12} sx={{ pb: 2 }}>
    <Typography variant="subtitle2">
      All done! Now check your inbox as you&apos;re in for a sweet
      treat!
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Grid container>
      <Grid item>
        <Button
          variant="contained"
          disableElevation
          endIcon={<IconBrandTelegram stroke={1.5} size="1.3rem" />}
        >
          Mail
        </Button>
      </Grid>
    </Grid>
  </Grid>
</Grid>
</ListItemWrapper>
<Divider />
<ListItemWrapper>
<ListItem alignItems="center">
  <ListItemAvatar>
    <Avatar alt="John Doe" src={User1} />
  </ListItemAvatar>
  <ListItemText
    primary={<Typography variant="subtitle1">John Doe</Typography>}
  />
  <ListItemSecondaryAction>
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <Typography variant="caption" display="block" gutterBottom>
          2 min ago
        </Typography>
      </Grid>
    </Grid>
  </ListItemSecondaryAction>
</ListItem>
<Grid container direction="column" className="list-container">
  <Grid item xs={12} sx={{ pb: 2 }}>
    <Typography component="span" variant="subtitle2">
      Uploaded two file on &nbsp;
      <Typography component="span" variant="h6">
        21 Jan 2020
      </Typography>
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Grid container>
      <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
          }}
        >
          <CardContent>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <IconPhoto stroke={1.5} size="1.3rem" />
                  <Typography variant="subtitle1">demo.jpg</Typography>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Grid>
</Grid>
</ListItemWrapper>
<Divider />
<ListItemWrapper>
<ListItem alignItems="center">
  <ListItemAvatar>
    <Avatar alt="John Doe" src={User1} />
  </ListItemAvatar>
  <ListItemText
    primary={<Typography variant="subtitle1">John Doe</Typography>}
  />
  <ListItemSecondaryAction>
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <Typography variant="caption" display="block" gutterBottom>
          2 min ago
        </Typography>
      </Grid>
    </Grid>
  </ListItemSecondaryAction>
</ListItem>
<Grid container direction="column" className="list-container">
  <Grid item xs={12} sx={{ pb: 2 }}>
    <Typography variant="subtitle2">
      It is a long established fact that a reader will be distracted
    </Typography>
  </Grid>
  <Grid item xs={12}>
    <Grid container>
      <Grid item>
        <Chip label="Confirmation of Account." sx={chipSuccessSX} />
      </Grid>
    </Grid>
  </Grid>
</Grid>
</ListItemWrapper> */
}
