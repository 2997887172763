import React from "react";

import { Grid, Pagination, Button, Menu, MenuItem, Box } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { setRowsPerPage } from "store/slices/table";
import scrollToTop from "utils/scrollToTop";
import { useDispatch } from "store";

interface Props {
  totalPages: number | undefined;
  setPage: ActionCreatorWithPayload<any>;
  setLimit: ActionCreatorWithPayload<any>;
  currentPage: number | undefined;
  itemCount: number | undefined;
  rowsPerPage?: number;
  handleRowPerPage?: (rows: number) => void;
  defaultRows?: number;
  rowLabel?: string;
}

const CustomPagination = ({
  totalPages,
  setPage,
  setLimit,
  currentPage,
  rowsPerPage = 10,
  handleRowPerPage,
  defaultRows = 10,
  rowLabel = "Rows"
}: Props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    scrollToTop();

    dispatch(setPage(value));
  };

  const handleRowsPerPage = (value: number) => {
    if (handleRowPerPage) {
      handleRowPerPage(value);
    }
    dispatch(setLimit(value));
    dispatch(setRowsPerPage(value));
    dispatch(setPage(1));
    handleClose();
  };

  return (
    <Box sx={{ py: 2, mt: "43px" }}>
      <Grid
        container
        className="table-pager"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Pagination
            count={totalPages ? totalPages : 1}
            color="primary"
            shape="rounded"
            size="medium"
            onChange={handleChange}
            page={currentPage ? currentPage : 1}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="inherit"
            endIcon={<ExpandMoreRoundedIcon />}
            onClick={handleClick}
            disableElevation
            sx={{
              border: "0.981667px solid #E5E5E8",
              borderRadius: "12px!important"
            }}
          >
            {`${rowsPerPage} ${rowLabel}`}
          </Button>
          <Menu
            id="menu-user-card-style1"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant="selectedMenu"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <MenuItem
              onClick={() => {
                handleRowsPerPage(defaultRows);
              }}
            >
              {`${defaultRows} ${rowLabel}`}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRowsPerPage(20);
              }}
            >
              {`20 ${rowLabel}`}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRowsPerPage(30);
              }}
            >
              {`30 ${rowLabel}`}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomPagination;
