import { gql } from "@apollo/client";

export const ADD_AGENT = gql`
  mutation ($input: AddAgentInput!) {
    addAgent(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isCreated
      }
    }
  }
`;

export const UPDATE_AGENT_STATUS_AND_PASSWORD = gql`
  mutation ($input: AgentPasswordAndStatus!) {
    updateAgentPasswordAndStatus(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isCreated
      }
    }
  }
`;

export const AGENT_SEND_RESET_LINK = gql`
  mutation ($input: ResetPasswordLinkDTO!) {
    sendResetPasswordLink(input: $input) {
      message
      success
    }
  }
`;

export const AGENT_SETTINGS_EDIT = gql`
  mutation ($input: EditAgentSettingsDTO!) {
    editAgentSettings(input: $input) {
      message
      success
    }
  }
`;

export const AGENT_DETAILS_EDIT = gql`
  mutation ($input: EditAgentDetailsDTO!) {
    editAgentDetails(input: $input) {
      message
      success
      data {
        _id
        email
        status
        isCreated
      }
    }
  }
`;
