import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

import MainCard from "ui-component/cards/MainCard";
import useGQL from "hooks/useGQL";
import Spinner from "components/spinner";
import SubscriptionTable from "./table/SubscriptionTable";
import { subscription } from "./table/constants/types";
import TableErrorMessage from "components/table/TableErrorMessage";
import { center } from "views/userManagement/agents";
import Search from "components/search/Search";
import { IPagination } from "interfaces";
import CustomizedBreadcrumb from "components/custom-breadcrumb";
import { clearState, setLimit, setPage } from "store/slices/table";
import { RootState, useDispatch, useSelector } from "store";
import CustomPagination from "components/pagination/CustomPagintion";
import SuperAdminAccesMenusGuard from "utils/route-guard/SuperAdminAccessMenuGuard";

const Subscriptions = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState<subscription[]>([]);
  const [debouncedValue, setDebouncedKeyValue] = useState("");

  const [count, setCount] = useState(1);

  const { page } = useSelector((state: RootState) => state.table);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [pageMeta, setPageMeta] = useState<IPagination>({
    limit: 10,
    skip: 0,
  });

  const limit = rowsPerPage;
  const skip = page > 0 ? limit * (page - 1) : 0;
  const { SUBSCRIPTION_LIST } = useGQL();

  const { error, loading, data, refetch } = SUBSCRIPTION_LIST({
    skip,
    limit,
    searchText: debouncedValue,
  });

  useEffect(() => {
    if (data?.getSubscriptions) {
      setSubscriptions(data.getSubscriptions?.subscriptions);
      setCount(data.getSubscriptions?.pagination?.total);
    }
  }, [data]);

  const handleRefetch = () => {
    refetch({
      input: {
        searchText: debouncedValue,
        limit: pageMeta?.limit,
        skip: pageMeta?.skip,
      },
    });
    setPage(0);
  };

  useEffect(() => {
    const limit = rowsPerPage;
    let skip = page > 1 ? rowsPerPage * (page - 1) : 0;
    if (debouncedValue) {
      skip = 0;
      setPage(0);
    }
    setPageMeta({ limit, skip });
    refetch({ input: { searchText: debouncedValue, limit, skip } });
  }, [rowsPerPage, page, debouncedValue, refetch]);

  useEffect(() => {
    handleRefetch();
  }, [debouncedValue]);

  const onGlobalPricingClick = () => {
    navigate("/subscription/global-setting");
  };

  const handleDebounce = (value: string) => {
    setDebouncedKeyValue(value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleRowPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);
  return (
    <>
      <CustomizedBreadcrumb title="Subscription" />
      <MainCard
        title=""
        content={false}
        sx={{ py: { md: 1 }, px: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          sx={{ py: 2 }}
          rowSpacing={2}
          columnSpacing={{ sx: 1, md: 2 }}
        >
          <Grid item xs={12} md={5}>
            <Typography variant="h3" sx={{ mt: "12px" }}>
              Subscriptions
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                sx={{ justifyContent: "flex-end", display: "flex" }}
              >
                <SuperAdminAccesMenusGuard>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={onGlobalPricingClick}
                    sx={{
                      maxWidth: { xs: "none", md: "146px" },
                      whiteSpace: "nowrap",
                      borderRadius: "32px",
                      padding: "10px 16px",
                    }}
                    size="large"
                  >
                    Global Pricing
                  </Button>
                </SuperAdminAccesMenusGuard>
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <Search {...{ search, handleDebounce, handleSearch }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <SubscriptionTable subscriptions={subscriptions} />

            {error && (
              <TableErrorMessage
                className={center}
                colSpan={8}
                message={error.message}
              />
            )}

            {!loading && !error && !subscriptions.length && (
              <TableErrorMessage
                className={center}
                colSpan={2}
                message="No Data Found"
              />
            )}

            <CustomPagination
              totalPages={Math.ceil(count / rowsPerPage)}
              setPage={setPage}
              setLimit={setLimit}
              currentPage={page}
              itemCount={count}
              rowsPerPage={rowsPerPage}
              handleRowPerPage={handleRowPerPage}
              defaultRows={10}
              rowLabel="Rows"
            />
          </>
        )}
      </MainCard>
    </>
  );
};

export default Subscriptions;
