import { gql } from '@apollo/client';

export const LIST_PAGES = gql`
    query ($input: GetPagesDTO!) {
        listPages(input: $input) {
            message
            pages {
                _id
                title
                slug
                status
                content
                updatedAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const LIST_PAGE = gql`
    query ($id: String!) {
        listPage(id: $id) {
            message
            page {
                _id
                title
                slug
                content
                status
                banner {
                    image
                    altText
                    slug
                    content
                    button {
                        heading
                        destinationUrl
                    }
                    uuid
                    index
                }
                homePage {
                    image
                    altText
                    slug
                    content
                    button {
                        heading
                        destinationUrl
                    }
                    uuid
                    index
                }
                imageColumn {
                    heading
                    sections {
                        image
                        alignment
                        iconHeading
                        subText
                    }
                    uuid
                    index
                }
                howItWorks {
                    heading
                    sections {
                        image
                        title
                        description
                    }
                    uuid
                    index
                }
                featuredProducts {
                    heading
                    sections {
                        image
                        name
                        productType
                    }
                    uuid
                    index
                }
                seoTags {
                    title
                    tags
                    description
                }
                faq {
                    showFaqs
                    uuid
                    index
                }
                testimonials {
                    heading
                    showTestimonials
                    uuid
                    index
                }
                createdAt
                updatedAt
            }
        }
    }
`;
