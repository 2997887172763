import { gql } from "@apollo/client";

export const CREATE_APP_USER = gql`
  mutation ($input: CreateAppUserDTO!) {
    createAppUser(input: $input) {
      message
      user {
        _id
        authProvider
        authProviderId
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        bio
        status
        lastLoggedInAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_APP_USER = gql`
  mutation ($id: String!, $input: UpdateAppUserDTO!) {
    updateAppUser(id: $id, input: $input) {
      message
      user {
        _id
        authProvider
        authProviderId
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        bio
        status
        lastLoggedInAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_APP_USER = gql`
  mutation ($id: String!) {
    deleteAppUser(id: $id) {
      message
    }
  }
`;

export const SEND_RESET_PASSWORD_LINK_BUYER = gql`
  mutation sendResetPasswordBuyer($input: BuyerResetPasswordDTO!) {
    sendResetPasswordBuyer(input: $input) {
      message
      success
    }
  }
`;
