// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <svg
      width="127"
      height="42"
      viewBox="0 0 127 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.8031 0.5C63.3733 0.5 69.5102 6.63685 69.5102 14.207V27.793C69.5102 35.3632 63.3733 41.5 55.8031 41.5C48.2329 41.5 42.0961 35.3631 42.0961 27.793V14.207C42.0961 6.63685 48.2329 0.5 55.8031 0.5ZM63.5447 18.3869L58.3349 18.9341C58.1206 18.3201 57.8394 17.9019 57.4912 17.6795C57.0447 17.3948 56.3751 17.2524 55.4822 17.2524C54.7858 17.2524 54.2992 17.3859 54.0224 17.6528C53.7456 17.9197 53.6072 18.3869 53.6072 19.0542C54.2858 19.3211 54.9287 19.5391 55.5358 19.7082C56.143 19.8683 57.4689 20.153 59.5135 20.5623C61.2189 20.9004 62.3885 21.4165 63.0224 22.1105C63.6564 22.8045 63.9733 23.6898 63.9733 24.7664C63.9733 25.9142 63.5358 26.8796 62.6608 27.6626C61.7769 28.4456 60.4867 28.8371 58.7903 28.8371C57.5135 28.8371 56.393 28.6413 55.4287 28.2498C54.7233 27.9562 54.0135 27.4668 53.2992 26.7817C53.2367 27.191 53.1742 27.4935 53.1117 27.6893C53.0492 27.8761 52.9153 28.1519 52.7099 28.5167H47.5939C47.8796 27.9295 48.0671 27.449 48.1564 27.0753C48.2367 26.6927 48.2769 26.1678 48.2769 25.5005V19.241C48.2769 18.5737 48.4108 17.8753 48.6787 17.1457C48.9465 16.4072 49.3126 15.8377 49.7769 15.4373C50.4376 14.859 51.2635 14.4808 52.2546 14.3029C53.2456 14.116 54.5581 14.0226 56.1921 14.0226C57.2099 14.0226 58.1564 14.0982 59.0314 14.2495C59.9064 14.4008 60.5939 14.6143 61.0939 14.8901C61.7903 15.2727 62.326 15.7265 62.701 16.2514C63.0671 16.7675 63.3483 17.4793 63.5447 18.3869ZM55.951 22.4975C55.1385 22.2929 54.3572 22.0571 53.6072 21.7902V22.6577C53.6072 23.3339 53.7189 23.8856 53.9421 24.3126C54.1564 24.7308 54.5269 25.0867 55.0537 25.3804C55.5715 25.674 56.134 25.8208 56.7412 25.8208C57.3215 25.8208 57.7635 25.6873 58.0671 25.4204C58.3617 25.1446 58.509 24.7931 58.509 24.366C58.509 23.9923 58.3572 23.6587 58.0537 23.3651C57.759 23.0803 57.0581 22.7912 55.951 22.4975Z"
        fill="url(#paint0_linear_10606_16068)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.9585 0.5C75.0954 0.5 71.9637 3.6317 71.9637 7.49485V16.3967C71.9955 16.3467 72.028 16.2983 72.0612 16.2514C72.4362 15.7265 72.9719 15.2727 73.6684 14.8901C74.1684 14.6143 74.8559 14.4008 75.7309 14.2495C76.6059 14.0982 77.5523 14.0226 78.5701 14.0226C80.2041 14.0226 81.5166 14.116 82.5076 14.3029C83.4987 14.4808 84.3246 14.859 84.9853 15.4373C85.3784 15.7763 85.7011 16.2365 85.9534 16.8178V7.49485C85.9534 3.6317 82.8217 0.5 78.9585 0.5ZM85.9534 28.5167H82.0523C81.8469 28.1519 81.713 27.8761 81.6505 27.6893C81.588 27.4935 81.5255 27.191 81.463 26.7817C80.7487 27.4668 80.0389 27.9562 79.3335 28.2498C78.3692 28.6413 77.2487 28.8371 75.9719 28.8371C74.2755 28.8371 72.9853 28.4456 72.1014 27.6626C72.0542 27.6204 72.0083 27.5776 71.9637 27.5344V34.5052C71.9637 38.3683 75.0954 41.5 78.9585 41.5C82.8217 41.5 85.9534 38.3683 85.9534 34.5052V28.5167ZM71.9637 21.8921C72.6323 21.3072 73.7274 20.8639 75.2487 20.5623C77.2934 20.153 78.6192 19.8683 79.2264 19.7082C79.8335 19.5391 80.4764 19.3211 81.155 19.0542C81.155 18.3869 81.0166 17.9197 80.7398 17.6528C80.463 17.3859 79.9764 17.2524 79.28 17.2524C78.3871 17.2524 77.7175 17.3948 77.271 17.6795C76.9228 17.9019 76.6416 18.3201 76.4273 18.9341L71.9637 18.4652V21.8921ZM78.8112 22.4975C79.6237 22.2929 80.405 22.0571 81.155 21.7902V22.6577C81.155 23.3339 81.0434 23.8856 80.8201 24.3126C80.6059 24.7308 80.2353 25.0867 79.7085 25.3804C79.1907 25.674 78.6282 25.8208 78.021 25.8208C77.4407 25.8208 76.9987 25.6873 76.6951 25.4204C76.4005 25.1446 76.2532 24.7931 76.2532 24.366C76.2532 23.9923 76.405 23.6587 76.7085 23.3651C77.0032 23.0803 77.7041 22.7912 78.8112 22.4975Z"
        fill="url(#paint1_linear_10606_16068)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10606_16068"
          x1="55.8031"
          y1="0.5"
          x2="55.8031"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#02399E" />
          <stop offset="1" stop-color="#6DA0F9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10606_16068"
          x1="78.9585"
          y1="0.5"
          x2="78.9585"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#02399E" />
          <stop offset="1" stop-color="#6DA0F9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
