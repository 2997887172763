import { useRoutes } from "react-router-dom";

// routes
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import MaintenanceRoutes from "./MaintenanceRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, ...MaintenanceRoutes]);
}
