import { useMutation, useQuery } from "@apollo/client";

import {
  ADD_ADMIN_PASSWORD,
  ADD_AGENCY,
  ADD_AGENT,
  ADD_BUYER,
  GET_ADMIN as ADMIN,
  DELETE_ADMIN as ADMIN_DELETE,
  UPDATE_ADMIN as ADMIN_UPDATE,
  CHANGE_PASSWORD,
  CREATE_ADMIN,
  CREATE_MENU as CREATE_MENUS,
  CREATE_PAGE as CREATE_PG,
  CREATE_TAXONOMY as CREATE_TAXONOMYS,
  DELETE_ADMIN_BULK,
  DELETE_PAGE as DELETE_PG,
  EDIT_ADMIN_PASSWORD,
  FORGOT_PASSWORD,
  GET_ADMIN_LIST,
  GET_AGENCY_DETAILS,
  GET_AGENCY_LIST,
  GET_AGENTLIST_AGENCY,
  GET_AGENT_LIST,
  GET_BUYERS_LIST,
  GET_BUYER_PROFILE,
  LIST_MENU as GET_LIST_MENU,
  LIST_MENUS as GET_LIST_MENUS,
  LIST_PAGE as GET_LIST_PAGE,
  LIST_PAGES as GET_LIST_PAGES,
  LIST_TAXONOMY as GET_LIST_TAXONOMY,
  LIST_TAXONOMIES as GET_TAXONOMIES_LIST,
  GET_USER_PROFILE,
  LOGIN,
  DELETE_MENU as MENU_DELETE,
  UPDATE_MENU as MENU_UPDATE,
  REGISTER,
  RESET_PASSWORD,
  DELETE_TAXONOMY as TAXONOMY_DELETE,
  UPDATE_TAXONOMY as TAXONOMY_UPDATE,
  UPDATE_ADMIN_STATUS,
  UPDATE_ADMIN_STATUS_BULK,
  UPDATE_AGENCY_DETAILS,
  UPDATE_AGENCY_STATUS_BULK,
  UPDATE_AGENT_STATUS_AND_PASSWORD,
  UPDATE_BUYER,
  UPDATE_BUYER_STATUS_BULK,
  EDIT_BUYER_PASSWORD,
  UPDATE_PAGE as UPDATE_PG,
  UPDATE_STATUS_AND_PASSWORD,
  UPDATE_SUBSCRIPTION_AGENCY,
  UPLOAD_AWS,
  GET_CONTACT_US_LIST,
  CONTACT_US_DETAILS,
  VIEW_AGENT_DETAILS,
  AGENT_SEND_RESET_LINK,
  AGENT_SETTINGS_EDIT,
  AGENT_DETAILS_EDIT,
  VIEW_AGENCY_AGENT_DETAILS,
  UPDATE_AGENT_IMAGE,
  GET_REVIEW_SETTINGS_DETAILS,
  UPDATE_REVIEW_SETTINGS_DETAIL,
  LIST_CMS_PAGES,
  UPDATE_CMS_PAGE,
  VIEW_CMS_PAGE,
  GET_REPORT_LIST,
  REPORTS_DETAILS,
  LOGOUT,
  UPDATE_AGENT_LOGO_COVER_IMAGE,
} from "../grapqhl";
import {
  CREATE_GLOBAL_SETTING,
  UPDATE_GLOBAL_SETTING,
} from "grapqhl/global-subscription-setting/mutations/mutation";
import { GET_GLOBAL_SETTING } from "grapqhl/global-subscription-setting/queries/queries";
import {
  SUBSCRIPTION_SETTING_CREATE,
  UPDATE_SUBSCRIPTION_SETTING_PLAN,
  UPDATE_SUBSCRIPTION_SETTING_PLAN_MONTHLY_FEE,
} from "grapqhl/subscription-setting/mutation/mutation";
import { LIST_SUBSCRIPTION } from "grapqhl/subscription/queries";
import { SEND_RESET_PASSWORD_LINK_BUYER } from "grapqhl/appUser/mutations";
import { CREATE_SETTINGS } from "grapqhl/settimgs/mutations";
import { GET_SETTINGS } from "grapqhl/settimgs/queries";
import {
  CANCEL_SUBSCRIPTION,
  RESTORE_SUBSCRIPTION,
} from "grapqhl/subscription/mutations";

type GetAdminListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetAgencyListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetAgenctListForAgencyDTO = {
  agencyId?: string;
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetPagesDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetMenusDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type GetTaxonsDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
  taxonomyId: string | undefined;
};

type GetTaxonByTaxonomyIdDTO = {
  taxonomyId: string | undefined;
};

type AgencyIdDTO = {
  agencyId?: string | undefined;
};

type GetAgentsListDTO = {
  searchText?: string;
  orderBy?: string;
  order?: string;
  limit?: number;
  skip?: number;
};

type BuyerIdDTO = {
  buyerId?: string | undefined;
};

type AgentIdDTO = {
  agentId?: string | undefined;
};

type ContactUsIdDTO = {
  contactUsId?: string | undefined;
};

type ReportDetailsDTO = {
  reportId?: string | undefined;
};

type GetSubscriptionsDTO = {
  limit?: number;
  skip?: number;
  searchText?: string;
};
type ReviewSettingsDTO = {
  reviewSettingsType?: string;
};

type viewCmsPageDTO = {
  slug?: string;
};

const useGQL = () => {
  const ADMIN_LOGIN = () => useMutation(LOGIN);
  const ADMIN_REGISTER = () => useMutation(REGISTER);
  const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
  const RESET_PASS = () => useMutation(RESET_PASSWORD);
  const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);

  /* CMS Pages */
  const LIST_ALL_CMS_PAGES = (input: GetListDTO = {}) =>
    useQuery(LIST_CMS_PAGES, { variables: { input } });

  const VIEW_CMS_PAGE_DETAIL = (input: viewCmsPageDTO = {}) =>
    useQuery(VIEW_CMS_PAGE, { variables: { input } });

  const UPDATE_CMS_PAGE_DETAILS = () => useMutation(UPDATE_CMS_PAGE);

  /* Review settings */
  const VIEW_REVIEW_SETTINGS_DETAILS = (input: ReviewSettingsDTO = {}) =>
    useQuery(GET_REVIEW_SETTINGS_DETAILS, { variables: { input } });

  const UPDATE_REVIEW_SETTINGS = () =>
    useMutation(UPDATE_REVIEW_SETTINGS_DETAIL);

  /* Upload to AWS */
  const UPLOAD_TO_AWS = () => useMutation(UPLOAD_AWS);
  const AGENT_IMAGE_UPDATE = () => useMutation(UPDATE_AGENT_IMAGE);

  /* Contact Us */
  const GET_CONTACTUS_LIST = (input: GetListDTO = {}) =>
    useQuery(GET_CONTACT_US_LIST, { variables: { input } });

  const CONTACTUS_DETAILS = (input: ContactUsIdDTO = {}) =>
    useQuery(CONTACT_US_DETAILS, { variables: { input } });

  // report listing
  const GET_ALL_REPORT_LIST = (input: GetListDTO = {}) =>
    useQuery(GET_REPORT_LIST, { variables: { input } });

  const REPORT_DETAILS = (input: ReportDetailsDTO = {}) =>
    useQuery(REPORTS_DETAILS, { variables: { input } });

  /* buyers */
  const GET_ALL_BUYERS_LIST = (input: GetAgentsListDTO = {}) =>
    useQuery(GET_BUYERS_LIST, { variables: { input } });
  const REGISTER_USER = () =>
    useMutation(ADD_BUYER, { refetchQueries: [GET_BUYERS_LIST] });
  const UPDATE_USER = () => useMutation(UPDATE_BUYER);
  const GET_BUYER_DETAILS = (input: BuyerIdDTO = {}) =>
    useQuery(GET_BUYER_PROFILE, { variables: { input } });

  const UPDATE_BUYERS_STATUS_BULK = () => useMutation(UPDATE_BUYER_STATUS_BULK);
  const BUYER_CHANGE_PASSWORD = () => useMutation(EDIT_BUYER_PASSWORD);

  /* agents */
  const GET_ALL_AGENTS = (input: GetAgentsListDTO = {}) =>
    useQuery(GET_AGENT_LIST, { variables: { input } });

  const REGISTER_AGENT = () =>
    useMutation(ADD_AGENT, {
      refetchQueries: [{ query: GET_AGENT_LIST, variables: { input: {} } }],
    });

  const UPDATE_AGENT_PASSWORD_STATUS = () =>
    useMutation(UPDATE_AGENT_STATUS_AND_PASSWORD);

  const VIEW_AGENTDETAILS = (input: AgentIdDTO = {}) =>
    useQuery(VIEW_AGENT_DETAILS, { variables: { input } });

  const AGENT_SEND_RESET_PASSWORD_LINK = () =>
    useMutation(AGENT_SEND_RESET_LINK);

  const AGENT_EDIT_SETTINGS = () => useMutation(AGENT_SETTINGS_EDIT);
  const UPDATE_AGENT_DETAILS = () => useMutation(AGENT_DETAILS_EDIT);
  const VIEW_AGENCY_FOR_AGENT_DETAILS = (input: AgentIdDTO = {}) =>
    useQuery(VIEW_AGENCY_AGENT_DETAILS, { variables: { input } });

  /* agency */
  const REGISTER_AGENCY = () =>
    useMutation(ADD_AGENCY, {
      refetchQueries: [{ query: GET_AGENCY_LIST, variables: { input: {} } }],
    });
  const UPDATE_SUBSCRIPTION = () => useMutation(UPDATE_SUBSCRIPTION_AGENCY);
  const UPDATE_AGENCY_PASSWORD_STATUS = () =>
    useMutation(UPDATE_STATUS_AND_PASSWORD);
  const GET_AGENCY_PROFILE = (input: AgencyIdDTO = {}) =>
    useQuery(GET_AGENCY_DETAILS, { variables: { input } });

  const GET_ALL_AGENCY = (input: GetAgencyListDTO = {}) =>
    useQuery(GET_AGENCY_LIST, {
      variables: { input },
      fetchPolicy: "network-only",
    });

  const GET_AGENTS_AGENCY = (input: GetAgenctListForAgencyDTO = {}) =>
    useQuery(GET_AGENTLIST_AGENCY, { variables: { input } });

  const UPDATE_AGENCY = () => useMutation(UPDATE_AGENCY_DETAILS);

  const UPDATE_AGENCY_BULK_STATUS = () =>
    useMutation(UPDATE_AGENCY_STATUS_BULK);

  /* admins */
  const ADD_ADMIN = () =>
    useMutation(CREATE_ADMIN, {
      refetchQueries: [{ query: GET_ADMIN_LIST, variables: { input: {} } }],
    });
  const EDIT_ADMIN_PASSWORDS = () => useMutation(EDIT_ADMIN_PASSWORD);
  const UPDATE_ADMIN_PASSWORD = () => useMutation(ADD_ADMIN_PASSWORD);
  const ADD_ADMIN_STATUS = () => useMutation(UPDATE_ADMIN_STATUS);
  const UPDATE_ADMIN_BULK_STATUS = () => useMutation(UPDATE_ADMIN_STATUS_BULK);
  const DELETE_ADMINS_BULK = () => useMutation(DELETE_ADMIN_BULK);

  const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) =>
    useQuery(GET_ADMIN_LIST, { variables: { input } });
  const GET_ADMIN = (id: string | undefined) =>
    useQuery(ADMIN, { variables: { id }, fetchPolicy: "network-only" });
  const UPDATE_ADMIN = () =>
    useMutation(ADMIN_UPDATE, {
      refetchQueries: [{ query: GET_ADMIN_LIST, variables: { input: {} } }],
    });
  const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
  const GET_ADMIN_PROFILE = () =>
    useQuery(GET_USER_PROFILE, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

  const CREATE_PAGE = () => useMutation(CREATE_PG);
  const UPDATE_PAGE = () => useMutation(UPDATE_PG);
  const DELETE_PAGE = () => useMutation(DELETE_PG);
  const LIST_PAGES = (input: GetPagesDTO = {}) =>
    useQuery(GET_LIST_PAGES, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const LIST_PAGE = (id: String) =>
    useQuery(GET_LIST_PAGE, { variables: { id } });

  const CREATE_MENU = () => useMutation(CREATE_MENUS);
  const LIST_MENUS = (input: GetMenusDTO = {}) =>
    useQuery(GET_LIST_MENUS, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const DELETE_MENU = () => useMutation(MENU_DELETE);
  const LIST_MENU = (id: String) =>
    useQuery(GET_LIST_MENU, { variables: { id } });
  const UPDATE_MENU = () => useMutation(MENU_UPDATE);

  const CREATE_TAXONOMY = () => useMutation(CREATE_TAXONOMYS);
  const UPDATE_TAXONOMY = () => useMutation(TAXONOMY_UPDATE);
  const DELETE_TAXONOMY = () => useMutation(TAXONOMY_DELETE);
  const LIST_TAXONOMY = (id: String | undefined) =>
    useQuery(GET_LIST_TAXONOMY, { variables: { id } });
  const LIST_TAXONOMIES = (input: GetListDTO = {}) =>
    useQuery(GET_TAXONOMIES_LIST, {
      variables: { input },
      notifyOnNetworkStatusChange: true,
    });
  const CREATE_GLOBAL_SUBSCRPTION_SETTING = () =>
    useMutation(CREATE_GLOBAL_SETTING);

  const GET_GLOBAL_SUBSCRPTION_SETTING = () => useQuery(GET_GLOBAL_SETTING);

  const CREATE_SUBSCRIPTION_SETTING = () =>
    useMutation(SUBSCRIPTION_SETTING_CREATE);

  const SUBSCRIPTION_LIST = (input: GetSubscriptionsDTO = {}) =>
    useQuery(LIST_SUBSCRIPTION, { variables: { input } });

  const UPDATE_GLOBAL_SUBSCRIPTION_SETTING = () =>
    useMutation(UPDATE_GLOBAL_SETTING);

  const LOGOUT_AS_AN_ADMIN = () => useMutation(LOGOUT);

  const BUYER_SEND_RESET_PASSWORD_LINK = () =>
    useMutation(SEND_RESET_PASSWORD_LINK_BUYER);

  const CREATE_UPDATE_SETTINGS = () =>
    useMutation(CREATE_SETTINGS, { refetchQueries: [GET_SETTINGS] });

  const AGENT_COVER_LOGO_UPDATE = () =>
    useMutation(UPDATE_AGENT_LOGO_COVER_IMAGE);

  const RESTORE_AGENCY_SUBSCRIPTION = () => useMutation(RESTORE_SUBSCRIPTION);

  const CANCEL_SUBSCRIPTIONS = () => useMutation(CANCEL_SUBSCRIPTION);

  const UPDATE_AUTOCHARGE_BILLING_CYCLE = () =>
    useMutation(UPDATE_SUBSCRIPTION_SETTING_PLAN);

  const UPDATE_MONTHLY_FEE_SETTING = () =>
    useMutation(UPDATE_SUBSCRIPTION_SETTING_PLAN_MONTHLY_FEE);

  return {
    ADMIN_LOGIN,
    ADMIN_REGISTER,
    FORGOT_PASS,
    RESET_PASS,
    GET_ALL_ADMINS,
    GET_ADMIN,
    UPDATE_ADMIN,
    DELETE_ADMIN,
    GET_ADMIN_PROFILE,
    ADMIN_CHANGE_PASSWORD,
    CREATE_PAGE,
    UPDATE_PAGE,
    DELETE_PAGE,
    LIST_PAGES,
    LIST_PAGE,
    CREATE_MENU,
    LIST_MENUS,
    DELETE_MENU,
    LIST_MENU,
    UPDATE_MENU,
    CREATE_TAXONOMY,
    UPDATE_TAXONOMY,
    DELETE_TAXONOMY,
    LIST_TAXONOMY,
    LIST_TAXONOMIES,
    REGISTER_AGENCY,
    UPDATE_SUBSCRIPTION,
    UPDATE_AGENCY_PASSWORD_STATUS,
    GET_ALL_AGENCY,
    GET_AGENCY_PROFILE,
    UPDATE_AGENCY,
    GET_ALL_AGENTS,
    REGISTER_AGENT,
    UPDATE_AGENT_PASSWORD_STATUS,
    GET_AGENTS_AGENCY,
    UPDATE_AGENCY_BULK_STATUS,
    GET_ALL_BUYERS_LIST,
    REGISTER_USER,
    UPDATE_USER,
    GET_BUYER_DETAILS,
    ADD_ADMIN,
    UPDATE_ADMIN_PASSWORD,
    ADD_ADMIN_STATUS,
    UPDATE_ADMIN_BULK_STATUS,
    DELETE_ADMINS_BULK,
    UPDATE_BUYERS_STATUS_BULK,
    BUYER_CHANGE_PASSWORD,
    UPLOAD_TO_AWS,
    EDIT_ADMIN_PASSWORDS,
    GET_CONTACTUS_LIST,
    CONTACTUS_DETAILS,
    CREATE_GLOBAL_SUBSCRPTION_SETTING,
    GET_GLOBAL_SUBSCRPTION_SETTING,
    CREATE_SUBSCRIPTION_SETTING,
    VIEW_AGENTDETAILS,
    AGENT_SEND_RESET_PASSWORD_LINK,
    AGENT_EDIT_SETTINGS,
    UPDATE_AGENT_DETAILS,
    VIEW_AGENCY_FOR_AGENT_DETAILS,
    AGENT_IMAGE_UPDATE,
    SUBSCRIPTION_LIST,
    VIEW_REVIEW_SETTINGS_DETAILS,
    UPDATE_REVIEW_SETTINGS,
    UPDATE_GLOBAL_SUBSCRIPTION_SETTING,
    LIST_ALL_CMS_PAGES,
    UPDATE_CMS_PAGE_DETAILS,
    VIEW_CMS_PAGE_DETAIL,
    GET_ALL_REPORT_LIST,
    REPORT_DETAILS,
    LOGOUT_AS_AN_ADMIN,
    BUYER_SEND_RESET_PASSWORD_LINK,
    CREATE_UPDATE_SETTINGS,
    AGENT_COVER_LOGO_UPDATE,
    RESTORE_AGENCY_SUBSCRIPTION,
    CANCEL_SUBSCRIPTIONS,
    UPDATE_AUTOCHARGE_BILLING_CYCLE,
    UPDATE_MONTHLY_FEE_SETTING,
  };
};

export default useGQL;
