import { gql } from "@apollo/client";

export const GET_REVIEW_SETTINGS_DETAILS = gql`
  query ($input: ViewReviewSettingsInput!) {
    viewReviewSettings(input: $input) {
      _id
      characterLimit
      pricingEstimation {
        minimum
        maximum
      }
      options {
        title
      }
    }
  }
`;
