import { HeadCell } from 'types';

export const headCells: HeadCell[] = [
  {
    id: 'image',
    numeric: false,
    label: '',
    align: 'left',
  },
  {
    id: 'from',
    numeric: false,
    label: 'From',
    align: 'left',
  },
  {
    id: 'activity',
    numeric: false,
    label: 'Activity',
    align: 'left',
  },
  {
    id: 'time',
    numeric: false,
    label: 'Time',
    align: 'left',
  },
];
