import { gql } from "@apollo/client";

export const SUBSCRIPTION_SETTING_CREATE = gql`
  mutation ($input: CreateSubscriptionSettingInputDTO!) {
    createSubscriptionSetting(input: $input) {
      message
      subscriptionSetting {
        _id
        agencyId
        commencementDate
        billingCycle
        residentialPricing {
          salePost
          rentPost
        }
        commercialPricing {
          salePost
          rentPost
        }
        relistingPricing {
          salePost
          rentPost
          holidayLettings
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_SETTING_PLAN = gql`
  mutation updateSubscriptionSettingCyleAndAutoCharge(
    $input: UpdateAutoChargeAndBillingCycleInputDTO!
  ) {
    updateSubscriptionSettingCyleAndAutoCharge(input: $input) {
      message
      success
    }
  }
`;

export const UPDATE_SUBSCRIPTION_SETTING_PLAN_MONTHLY_FEE = gql`
  mutation updateMonthlyFeeAndSubscriptionSetting(
    $input: UpdateMonthlyFeeAndSubscriptionSettingWithFeeInputDTO!
  ) {
    updateMonthlyFeeAndSubscriptionSetting(input: $input) {
      message
      success
    }
  }
`;
