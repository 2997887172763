import { gql } from "@apollo/client";

export const GET_AGENT_LIST = gql`
  query ($input: GetListDTO!) {
    listAllAgents(input: $input) {
      data {
        _id
        firstName
        lastName
        phoneNumber
        agencyName
        status
        tradingName
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_AGENTLIST_AGENCY = gql`
  query ($input: ListAgentInputForAgency!) {
    listAgentForAgency(input: $input) {
      data {
        _id
        firstName
        lastName
        agentImage
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const VIEW_AGENT_DETAILS = gql`
  query ($input: AgentDetailDTO!) {
    viewAgentDetails(input: $input) {
      _id
      firstName
      lastName
      phoneNumber
      agentLicenseNumber
      status
      email
      hasAgencyAccess
      agencyId
      agencyName
      agentImage
    }
  }
`;

export const VIEW_AGENCY_AGENT_DETAILS = gql`
  query ($input: AgentDetailDTO!) {
    viewAgencyDetailsForAgent(input: $input) {
      _id
      ABN
      ACN
      dateRegistered
      phoneNumber
      address
      email
      agencyName
      isCompany
      tradingName
    }
  }
`;
