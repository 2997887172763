import { gql } from "@apollo/client";

export const GET_AGENCY_LIST = gql`
  query ($input: GetListDTO!) {
    listAllAgency(input: $input) {
      data {
        _id
        agencyName
        email
        numberOfAgents
        ABN
        status
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_AGENCY_DETAILS = gql`
  query ($input: AgencyDetailInput!) {
    getAgencyDetail(input: $input) {
      data {
        _id
        agencyName
        officeEmailAddress
        companyName
        name
        contactNumber
        ABN
        ACN
        isCompany
        isSoleTrader
        officeContactNumber
        licenseNumber
        createdAt
        logoUrl
        coverPhoto
        tradingName
        directorName
        status
        address {
          displayAddress
          suburb
          city
          postalCode
          state
        }
        licenseesInCharge {
          licenseInCharge
          officeLicenseNumber
        }
        totalPropertiesPosted
        totalPropertiesSold
        totalResenditialProperties
        totalCommercialProperties
        totalLeasedProperties
        totalAgents
        subscriptionSettingId
      }
    }
  }
`;
