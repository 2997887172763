import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATION_FOR_ADMIN = gql`
  query GetNotificationsForAdmin($input: GetNotificationDTO!) {
    getNotificationsForAdmin(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      data {
        _id
        activityText
        date
        propertyId
        propertyTitle
        agentOrAgencyName
        agentOrAgencyRole
        agencyOrAgentId
        isRead
        agentOrAgencyPhoto
      }
      unreadCount
    }
  }
`;

export const updateReadStatus = gql`
  query UpdateNotification($updateNotificationId: String!) {
    updateNotification(id: $updateNotificationId) {
      message
    }
  }
`;
