import _debounce from "lodash.debounce";
import { useEffect, useMemo } from "react";

type fn = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
) => void;

const useDebouncedSearch = (handleSearch: fn) => {
  const debouncedSearch = useMemo(() => {
    return _debounce(handleSearch, 200);
  }, []);

  useEffect(() => {
    debouncedSearch.cancel();
  });

  return [debouncedSearch];
};

export default useDebouncedSearch;
