import { gql } from "@apollo/client";

export const LIST_CMS_PAGES = gql`
  query ($input: GetListDTO!) {
    listAllPages(input: $input) {
      data {
        _id
        title
        uploadedBy
        createdAt
        deletedAt
        slug
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;
export const VIEW_CMS_PAGE = gql`
  query ($input: PageDetailsInput!) {
    getPageDetails(input: $input) {
      _id
      title
      slug
      content
      deletedAt
    }
  }
`;
