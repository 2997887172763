import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/authentication/pages/Login3"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/authentication/pages/Register3"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/authentication/pages/ForgotPassword3"))
);
const AuthMailCheck = Loadable(
  lazy(() => import("views/authentication/pages/CheckMail3"))
);
const AuthCodeVerification = Loadable(
  lazy(() => import("views/authentication/pages/CodeVerification3"))
);
const AuthResetPassword = Loadable(
  lazy(() => import("views/authentication/pages/ResetPassword3"))
);
const AuthNewPassword = Loadable(
  lazy(() => import("views/authentication/pages/NewPassword"))
);

const AuthBackToLogin = Loadable(
  lazy(() => import("views/authentication/auth-forms/BacktoLogin"))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },
    // {
    //     path: '/register',
    //     element: <AuthRegister />
    // },
    {
      path: "/forgot",
      element: <AuthForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <AuthResetPassword />,
    },
    {
      path: "/new-password",
      element: <AuthNewPassword />,
    },
    {
      path: "/mail-check",
      element: <AuthMailCheck />,
    },
    {
      path: "/code-verification",
      element: <AuthCodeVerification />,
    },
    {
      path: "/forgot-success",
      element: <AuthBackToLogin />,
    },
  ],
};

export default LoginRoutes;
