import { gql } from "@apollo/client";

export const UPDATE_ADMIN = gql`
  mutation ($id: String!, $input: UpdateAdminDTO!) {
    updateAdmin(id: $id, input: $input) {
      message
      admin {
        _id
        name
        email
        status
        role
        phone
        displayImage
      }
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation ($id: String!) {
    removeAdmin(id: $id) {
      message
      admin {
        _id
      }
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation ($input: CreateAdminInput!) {
    registerAdmin(input: $input) {
      _id
      name
      email
      role
      status
      phone
    }
  }
`;

export const ADD_ADMIN_PASSWORD = gql`
  mutation ($input: AddAdminPasswordDTO!) {
    createAdminPassword(input: $input) {
      message
      admin {
        _id
        name
        email
        role
        status
        phone
      }
    }
  }
`;

export const UPDATE_ADMIN_STATUS = gql`
  mutation ($input: UpdateAdminStatusDTO!) {
    updateAdminStatus(input: $input) {
      message
      admin {
        _id
        name
        email
        role
        status
        phone
      }
    }
  }
`;

export const UPDATE_ADMIN_STATUS_BULK = gql`
  mutation ($input: AdminStatusBulkUpdate!) {
    adminBulkStatusUpdate(input: $input) {
      message
      success
    }
  }
`;

export const DELETE_ADMIN_BULK = gql`
  mutation ($input: AdminDeleteBulkDTO!) {
    adminBulkDelete(input: $input) {
      message
      success
    }
  }
`;

export const EDIT_ADMIN_PASSWORD = gql`
  mutation ($id: String!, $input: UpdateAdminPasswordDTO!) {
    updateAdminPassword(id: $id, input: $input) {
      message
      admin {
        _id
        name
        email
        role
        status
        phone
        displayImage
      }
    }
  }
`;
