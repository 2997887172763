import useGQL from "hooks/useGQL";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROLE } from "store/constant";

const SuperAdminGuard = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const { GET_ADMIN_PROFILE } = useGQL();
  const { data } = GET_ADMIN_PROFILE();

  useEffect(() => {
    if (data?.getUserProfile) {
      if (data?.getUserProfile?.role !== ADMIN_ROLE.SUPER_ADMIN) {
        navigate("/login", { replace: true });
      }
    }
  }, [navigate, data?.getUserProfile]);

  return <>{children}</>;
};

export default SuperAdminGuard;
