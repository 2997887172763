import { HeadCell } from 'types';

export const defaultAgents = {
  _id: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  agencyName: '',
  status: '',
};

export const headCells: HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    label: 'First Name',
    align: 'left',
  },
  {
    id: 'lastName',
    numeric: false,
    label: 'Last Name',
    align: 'left',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    label: 'Contact Number',
    align: 'left',
  },
  {
    id: 'agencyName',
    numeric: false,
    label: 'Agency',
    align: 'left',
  },

  {
    id: 'status',
    numeric: false,
    label: 'Status ',
    align: 'right',
  },
];
