import dayjs from "dayjs";
import { MONTH_ABBREVIATION } from "store/constant";
import { IDateFilter } from "views/dashboard/constant/variables";

export const formatDateToDayMthYr = (date: string | Date) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const formatDateToLocale = (date: Date) => {
  return dayjs(date).format("D MMMM YYYY");
};

export const formatDate = (date: string | Date) => {
  return dayjs(date).format("DD-MM-YYYY");
};

export const getTimeFromDate = (date: Date) => {
  return dayjs(date).format("hh:mm a");
};

export const formatDateToLocaleString = (date: Date) => {
  return dayjs(date).format("D MMM YYYY h:mm a");
};

export const getStartOfDay = () => {
  let start = new Date();
  start.setUTCHours(0, 0, 0, 0);

  return start;
};

export const getEndOfDay = () => {
  let end = new Date();
  end.setUTCHours(23, 59, 59, 999);

  return end;
};

export const getSortedtMonths = (value: string) => {
  if (value === "LAST_MONTH") {
    const sorted = sortMonth();

    return [...sorted.slice(11)];
  }

  if (value === "LAST_THREE_MONTHS") {
    const sorted = sortMonth();

    return [...sorted.slice(9)];
  }

  if (value === "LAST_SIX_MONTHS") {
    const sorted = sortMonth();

    return [...sorted.slice(6)];
  }

  if (value === "LAST_TWELVE_MONTHS") {
    const sorted = sortMonth();

    return [...sorted.slice(0)];
  }

  return [""];
};

export const sortMonth = () => {
  var cur = new Date().getMonth();

  var sorted = [
    ...MONTH_ABBREVIATION.slice(cur),
    ...MONTH_ABBREVIATION.slice(0, cur),
  ];

  return sorted;
};

export const checkForCustomDate = (dateFilter?: IDateFilter) => {
  if (dateFilter?.from && dateFilter?.to) {
    if (dateFilter.from.getTime() === getStartOfDay().getTime()) {
      if (dateFilter.to.getTime() === getEndOfDay().getTime()) {
        return false;
      }
    }
  }

  return true;
};

export const formatDateInReverse = (date: string | Date) => {
  return dayjs(date).format("DD-MM-YYYY");
};
