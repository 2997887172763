import { gql } from "@apollo/client";

export const ADD_BUYER = gql`
  mutation createNewBuyer($input: CreateAppUserDTO!) {
    createNewBuyer(input: $input) {
      message
      success
    }
  }
`;

export const UPDATE_BUYER = gql`
  mutation updateBuyerProfile($input: UpdateBuyerDTO!) {
    updateBuyerProfile(input: $input) {
      message
      success
    }
  }
`;

export const UPDATE_BUYER_STATUS_BULK = gql`
  mutation ($input: BuyerStatusBulkUpdate!) {
    buyerBulkStatusUpdate(input: $input) {
      message
      success
    }
  }
`;

export const EDIT_BUYER_PASSWORD = gql`
  mutation ($id: String!, $input: UpdateBuyerPasswordDTO!) {
    updateBuyerPassword(id: $id, input: $input) {
      message
    }
  }
`;
