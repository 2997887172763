import { gql } from "@apollo/client";

export const CREATE_GLOBAL_SETTING = gql`
  mutation (
    $createGlobalSubscriptionSetting: CreateGlobalSubscriptionSettingInputDTO!
  ) {
    createGlobalSubscriptionSetting(
      createGlobalSubscriptionSetting: $createGlobalSubscriptionSetting
    ) {
      message
      subscriptionSetting {
        monthlyFee
        residentialPricing {
          salePost
          rentPost
        }
        commercialPricing {
          salePost
          rentPost
        }
        relistingPricing {
          salePost
          rentPost
          holidayLettings
        }
      }
    }
  }
`;

export const UPDATE_GLOBAL_SETTING = gql`
  mutation (
    $updateGlobalSubscriptionSetting: UpdateGlobalSubscriptionSettingInputDTO!
  ) {
    updateGlobalSubscriptionSetting(
      updateGlobalSubscriptionSetting: $updateGlobalSubscriptionSetting
    ) {
      message
      subscriptionSetting {
        monthlyFee
        residentialPricing {
          salePost
          rentPost
        }
        commercialPricing {
          salePost
          rentPost
        }
        relistingPricing {
          salePost
          rentPost
          holidayLettings
        }
      }
    }
  }
`;
