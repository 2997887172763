import { createSlice } from "@reduxjs/toolkit";

// types
import { SnackbarProps } from "types/snackbar";

const initialState: SnackbarProps = {
  action: false,
  open: false,
  message: "Note archived",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  variant: "default",
  alert: {
    color: "primary",
    variant: "filled",
  },
  transition: "Fade",
  close: true,
  actionButton: false,
  timeout: 3000,
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar(state, action) {
      const {
        open,
        message,
        anchorOrigin,
        variant,
        alert,
        transition,
        close,
        actionButton,
        timeout,
      } = action.payload;

      state.action = !state.action;
      state.open = open || initialState.open;
      state.message = message || initialState.message;
      state.anchorOrigin = {
        vertical: anchorOrigin?.vertical || initialState.anchorOrigin.vertical,
        horizontal:
          anchorOrigin?.horizontal || initialState.anchorOrigin.horizontal,
      };
      state.variant = variant || initialState.variant;
      state.alert = {
        color: alert?.color || initialState.alert.color,
        variant: alert?.variant || initialState.alert.variant,
      };
      state.transition = transition || initialState.transition;
      state.close = close === false ? close : initialState.close;
      state.actionButton = actionButton || initialState.actionButton;
      state.timeout = timeout || 3000;
    },

    closeSnackbar(state) {
      state.open = false;
    },
    clearSnackbarState() {
      return initialState;
    },
  },
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar, clearSnackbarState } =
  snackbar.actions;
