import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
// import { IconBell } from '@tabler/icons';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  GET_ALL_NOTIFICATION_FOR_ADMIN,
  updateReadStatus,
} from 'grapqhl/adminNotification/query';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { NotificationsForAdmin } from 'views/NotificationList/constants/types';
import {
  MARK_ALL_READ,
  MARK_AS_READ,
} from 'grapqhl/adminNotification/mutation';

// notification status options
const status = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'unread',
    label: 'Unread',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */

  //localstates
  const [notifications, setNotifications] = useState<NotificationsForAdmin[]>(
    []
  );
  const markAsReadHandler = (id: string) => {
    const updatedList = notifications?.map((item: any) => {
      if (item._id === id) {
        item = { ...item, isRead: true };
      }
      return item;
    });
    setNotifications(updatedList);
    // const notification = notifications.find((item: any) => (item._id = id));
  };

  const [notificationId, setNotificationId] = useState<string | null>(null);

  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    event?.target.value && setValue(event?.target.value);
  };

  const { loading, error, data, refetch } = useQuery(
    GET_ALL_NOTIFICATION_FOR_ADMIN,
    {
      variables: {
        input: { skip: 0, limit: 5 },
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data?.getNotificationsForAdmin) {
          setNotifications(data.getNotificationsForAdmin?.data);
        }
      },
    }
  );

  /* read clicked notification */
  const [markAsRead, {}] = useMutation(MARK_AS_READ, {
    variables: {
      updateNotificationsId: notificationId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setNotificationId(null);
    },
  });

  /* read clicked mark all read */
  const [markAllRead, {}] = useMutation(MARK_ALL_READ, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {},
  });

  useEffect(() => {
    if (notificationId) {
      markAsRead();
    }
  }, [notificationId]);

  const handleAllRead = async () => {
    await markAllRead();
    refetch();
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down('md')]: {
            mr: 2,
          },
        }}
      >
        <Badge
          badgeContent={
            data?.getNotificationsForAdmin?.unreadCount > 99
              ? `99+`
              : data?.getNotificationsForAdmin?.unreadCount
          }
          color="error"
          sx={{
            position: 'absolute',
            marginLeft: '2.5rem',
            marginBottom: '0.5rem',
          }}
        ></Badge>
        <Avatar
          variant="rounded"
          // sx={{
          //   ...theme.typography.commonAvatar,
          //   ...theme.typography.mediumAvatar,
          //   transition: 'all .2s ease-in-out',
          //   background:
          //     theme.palette.mode === 'dark'
          //       ? theme.palette.dark.main
          //       : theme.palette.secondary.light,
          //   color:
          //     theme.palette.mode === 'dark'
          //       ? theme.palette.warning.dark
          //       : theme.palette.secondary.dark,
          //   '&[aria-controls="menu-list-grow"],&:hover': {
          //     background:
          //       theme.palette.mode === 'dark'
          //         ? theme.palette.warning.dark
          //         : theme.palette.secondary.dark,
          //     color:
          //       theme.palette.mode === 'dark'
          //         ? theme.palette.grey[800]
          //         : theme.palette.secondary.light,
          //   },
          // }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
          sx={{ width: 36, height: 36, borderRadius: '8px', cursor: 'pointer' }}
        >
          <NotificationsNoneOutlinedIcon fontSize="small" color="secondary" />
        </Avatar>
      </Box>

      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions
              position={matchesXs ? 'top' : 'top-right'}
              in={open}
              {...TransitionProps}
            >
              <Paper sx={{ borderRadius: '12px', width: '300px' }}>
                {open && (
                  <MainCard
                    border={false}
                    elevation={12}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ pt: 3, pb: 0.75, px: 2 }}
                        >
                          <Grid item>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography variant="h4" fontWeight={500}>
                                All Notification
                              </Typography>
                              <Chip
                                size="small"
                                label={
                                  data?.getNotificationsForAdmin?.unreadCount >
                                  99
                                    ? `99+`
                                    : data?.getNotificationsForAdmin
                                        ?.unreadCount
                                }
                                sx={{
                                  color: '#ffffff!important',
                                  bgcolor: '#FFC107',
                                }}
                              />
                            </Stack>
                          </Grid>
                          <Grid item>
                            <Typography
                              component={Link}
                              to="#"
                              variant="subtitle2"
                              color="primary"
                              onClick={() => handleAllRead()}
                            >
                              Mark as all read
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <PerfectScrollbar
                          style={{
                            height: '100%',
                            maxHeight: 'calc(100vh - 205px)',
                            overflowX: 'hidden',
                          }}
                        >
                          <NotificationList
                            notifications={notifications}
                            onClose={(e) => handleClose(e)}
                            handleRefetch={refetch}
                            onRead={(val) => setNotificationId(val)}
                            markAsReadHandler={markAsReadHandler}
                          />
                        </PerfectScrollbar>
                      </Grid>
                    </Grid>
                    <Divider />
                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                      <Button
                        component={Link}
                        to="/notifications"
                        size="small"
                        color="inherit"
                        sx={{
                          color: '#1E88E5!important',
                          fontWeight: 'normal',
                          marginBottom: '4px',
                        }}
                        disableElevation
                      >
                        View All
                      </Button>
                    </CardActions>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
