import { SubscriptionBillingCycle } from "interfaces";

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export enum SubscriptionBillingCycleNames {
  MONTHLY = "Monthly",
  QUARTERLY = "Quarterly",
  YEARLY = "Yearly",
  WEEKLY = "Weekly",
}

export enum AutoChargeEnum {
  ON = "true",
  OFF = "false",
}

export enum SubscriptionPricesTitle {
  RESIDENTIAL = "Residential Pricing",
  COMMERCIAL = "Commercial Pricing",
  RELISTING = "Relisting Pricing",
}

export enum SubscriptionSettingPricing {
  RESIDENTIAL_PRICNG = "residentialPricing",
  COMMERCIAL_PRICNG = "commercialPricing",
  RELISTING_PRICING = "relistingPricing",
}

export const BASE_SUBSCRIPTION = "BASE";

export const BASE_SUBSCRIPTION_APBR = "Base Subscription APBR";

export enum ADMIN_ROLE {
  ADMIN = "admin",
  SUPER_ADMIN = "superAdmin",
}

export const MAX_POST_DIGITS = 10;
export const MAX_HOLIDAY_LETING_DIGITS = 3;

export enum PropertyStatus {
  SOLD = "sold",
  LIVE = "live",
  RENT = "rent",
  WITHDRAW = "withdraw",
  UNDER_OFFER = "underoffer",
  LEASED = "leased",
  UNDER_CONTRACT = "undercontract",
}

export enum Property {
  "commercial" = "commercial",
  "residential" = "residential",
  "holidayLetting" = "holidayLetting",
}

export const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const MONTH_ABBREVIATION = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const STARTING = "STARTING";
export const ENDING = "ENDING";

export enum ReviewSettingType {
  HOLIDAY_LETTING = "HOLIDAY_LETTING",
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL = "COMMERCIAL",
}

//2MB
export const MAX_IMAGE_SIZE = 2048000;

export enum AgencyStatus {
  active = "active",
  passive = "passive",
  deleted = "deleted",
  incomplete = "incomplete",
}

export enum Mode {
  Add = "Add",
  Edit = "Edit",
}

export const SUBSCRIPTION_APBR = "Base ABPR Plan";

export enum SubscriptionStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
}

export enum AutoChargeValues {
  On = "On",
  OFF = "Off",
}

export enum BillingCycleValues {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
  WEEK = "WEEK",
}

export enum BillingCycle {
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
  Week = "Week",
}

export const autoChargeList = [
  {
    value: "true",
    label: "On",
  },
  {
    value: "false",
    label: "Off",
  },
];

export const billingCycleList = [
  {
    value: SubscriptionBillingCycle.MONTHLY,
    label: SubscriptionBillingCycleNames.MONTHLY,
  },
  {
    value: SubscriptionBillingCycle.QUARTERLY,
    label: SubscriptionBillingCycleNames.QUARTERLY,
  },
  {
    value: SubscriptionBillingCycle.YEARLY,
    label: SubscriptionBillingCycleNames.YEARLY,
  },
  {
    value: SubscriptionBillingCycle.WEEKLY,
    label: SubscriptionBillingCycleNames.WEEKLY,
  },
];

export enum PropertyListingType {
  EARLYBIRD = "earlyBird",
  FULL = "full",
}

export const GST_PERCENTAGE = 11;

export enum SaleTypeEnum {
  "sale" = "sale",
  "rent" = "rent",
  "sold" = "sold",
}
