/* eslint no-underscore-dangle: 0 */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { Agent } from "../constants/types";

import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import { headCells } from "../constants/variables";
import { useNavigate } from "react-router-dom";
import { AgencyStatus } from "store/constant";

type AgentTableProps = {
  agents: Agent[];
  handleEdit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

const AgentTable = ({ agents }: AgentTableProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const [selected, setSelected] = useState<string[]>([]);

  const handleEditLink = (agentId: string) => {
    navigate(`/agents/view-agent/${agentId}`);
  };

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };
  const isSelected = (agencyName: string) =>
    selected.indexOf(agencyName) !== -1;

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {agents.length !== 0 &&
              agents.map((agent, index) => {
                if (typeof agent === "number") return null;

                const isItemSelected = isSelected(agent._id);
                return (
                  <TableRow
                    hover
                    key={agent._id}
                    tabIndex={-1}
                    onClick={(e) => handleEditLink(agent._id)}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell align="left">{agent.firstName}</TableCell>
                    <TableCell align="left">{agent.lastName}</TableCell>
                    <TableCell align="left">{agent.phoneNumber}</TableCell>

                    <TableCell align="left">
                      {agent.tradingName || "N/A"}
                    </TableCell>
                    <TableCell align="right">
                      {agent.status === AgencyStatus.active && "Active"}

                      {agent.status === AgencyStatus.passive && "Disabled"}
                      {agent.status === AgencyStatus.incomplete && "Incomplete"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AgentTable;
