import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { subscription } from "./constants/types";
import { headCells } from "./constants/variables";
import { formatDateToDayMthYr } from "utils/dateHelper";

interface SubscriptionTableProps {
  subscriptions: subscription[];
}

const SubscriptionTable = ({ subscriptions }: SubscriptionTableProps) => {
  const navigate = useNavigate();

  const onAgencyNameClick = (agencyId: string) => {
    navigate(`/subscription/${agencyId}`);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      padding={headCell.disablePadding ? "none" : "normal"}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {subscriptions.length
              ? subscriptions.map((item) => {
                  return (
                    <TableRow
                      hover
                      key={item._id}
                      tabIndex={-1}
                      onClick={() => {
                        onAgencyNameClick(item.agencyId);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell align="left">
                        <Link underline="none">{item.agencyName}</Link>
                      </TableCell>
                      <TableCell align="left">{item.agencyOwner}</TableCell>
                      <TableCell align="left">
                        {formatDateToDayMthYr(item.startDate)}
                      </TableCell>
                      <TableCell align="left">{item.plan}</TableCell>
                      <TableCell align="right">{item.status}</TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default SubscriptionTable;
