// third-party
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type order = "asc" | "desc";

type SortType = {
  order: order;
  orderBy: string;
};

type TableType = {
  searchText: string;
  page: number;
  rowsPerPage: number;
  sort: SortType;
  limit: number;
};

const initialState: TableType = {
  searchText: "",
  limit: 10,
  page: 0,
  rowsPerPage: 10,
  sort: {
    order: "desc",
    orderBy: "createdAt",
  },
};
const slice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<number>) {
      state.rowsPerPage = action.payload;
    },
    setSort(state, action: PayloadAction<SortType>) {
      state.sort = action.payload;
    },
    clearState() {
      return initialState;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const {
  setSearchText,
  setPage,
  setRowsPerPage,
  setSort,
  setLimit,
  clearState,
} = slice.actions;
