import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Spinner from "components/spinner";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";
import { useDialog } from "views/userManagement/admins/hooks/useDialogs";

import AgentTable from "./table/AgentTable";
//ICONS
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TableErrorMessage from "components/table/TableErrorMessage";
import { RootState, useDispatch, useSelector } from "store";
import useDebouncedSearch from "hooks/common/useDebounceSearch";
import { Agent } from "./constants/types";
import { useLazyQuery } from "@apollo/client";
import { GET_AGENT_LIST } from "grapqhl";
import CustomizedBreadcrumb from "components/custom-breadcrumb";
import { clearState, setLimit, setPage } from "store/slices/table";
import CustomPagination from "components/pagination/CustomPagintion";

export const center = {
  textAlign: "center",
  direction: "row",
  justifyContent: "center",
};

const AgentsList = () => {
  // const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({
    limit: 10,
    skip: 0,
  });

  const dispatch = useDispatch();

  const { page } = useSelector((state: RootState) => state.table);

  const [debouncedSearch] = useDebouncedSearch(
    (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | undefined
    ) => {
      if (event) {
        setSearch(event?.target.value);
      }
    }
  );
  const [agents, setAgents] = useState<Agent[]>([]);

  /* queries & mutations */
  const { sort } = useSelector((state: RootState) => state.table);
  const [listAllAgents, { loading, error, refetch }] = useLazyQuery(
    GET_AGENT_LIST,
    {
      onCompleted(data) {
        if (data?.listAllAgents) {
          setAgents(data.listAllAgents?.data);
          setCount(data.listAllAgents?.pagination?.total);
        }
      },
      fetchPolicy: "network-only",
    }
  );

  /* local states */

  const { handleDialogOpen } = useDialog(); //TODO: Change this to global component

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  useEffect(() => {
    const limit = rowsPerPage;
    let skip = page > 1 ? rowsPerPage * (page - 1) : 0;
    if (search) {
      setPage(1);
      skip = 0;
    }
    setPageMeta({ limit, skip });
    listAllAgents({
      variables: {
        input: {
          searchText: search,
          limit: pageMeta?.limit,
          skip: pageMeta?.skip,
          order: sort.order,
          orderBy: sort.orderBy,
        },
      },
    });
  }, [
    listAllAgents,
    sort.order,
    sort.orderBy,
    pageMeta.limit,
    pageMeta.skip,
    rowsPerPage,
    page,
    search,
  ]);

  const handleRefetch = () => {
    refetch({
      input: {
        searchText: search,
        limit: pageMeta?.limit,
        skip: pageMeta?.skip,
        order: sort.order,
        orderBy: sort.orderBy,
      },
    });
    setPage(0);
  };

  const handleEdit = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    id: string
  ) => {
    const selection = agents.find((agent) => agent._id === id);
    if (selection) {
      handleDialogOpen();
    }
  };

  const handleRowPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  return (
    <>
      <CustomizedBreadcrumb title="Agents" />

      <MainCard
        title=""
        content={false}
        sx={{ py: { md: 1 }, px: { xs: 2, md: 3 } }}
      >
        <Grid
          container
          sx={{ py: 2 }}
          rowSpacing={2}
          columnSpacing={{ sx: 1, md: 2 }}
        >
          <Grid item xs={12} md={5}>
            <Typography variant="h3" sx={{ mt: "12px" }}>
              Agents
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={4}
                md={6}
                sx={{ justifyContent: "flex-end", display: "flex" }}
              >
                <Button
                  fullWidth
                  startIcon={
                    <AddBoxOutlinedIcon
                      sx={{
                        fontSize: "1.65rem!important",
                      }}
                    />
                  }
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/agents/add-new-agent"
                  sx={{
                    maxWidth: { xs: "none", md: "142px" },
                    whiteSpace: "nowrap",
                    borderRadius: "32px",
                    padding: "10px 16px",
                  }}
                  size="large"
                >
                  New Agent
                </Button>
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <TextField
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: "1rem" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={debouncedSearch}
                  placeholder="Search"
                  size="small"
                />
              </Grid>
            </Grid>
            {/* </Stack> */}
          </Grid>
        </Grid>
        {/* table */}
        {loading ? (
          <Spinner />
        ) : (
          <>
            <AgentTable
              {...{
                agents,
                handleEdit,
                handleRefetch,
              }}
            />

            {error && (
              <TableErrorMessage
                className={center}
                colSpan={8}
                message={error.message}
              />
            )}
            {!loading && !error && !agents.length && (
              <TableErrorMessage
                className={center}
                colSpan={2}
                message="No Data Found"
              />
            )}

            <CustomPagination
              totalPages={Math.ceil(count / rowsPerPage)}
              setPage={setPage}
              setLimit={setLimit}
              currentPage={page}
              itemCount={count}
              rowsPerPage={rowsPerPage}
              handleRowPerPage={handleRowPerPage}
              defaultRows={10}
              rowLabel="Rows"
            />
          </>
        )}
      </MainCard>
    </>
  );
};

export default AgentsList;
