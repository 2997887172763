import { gql } from "@apollo/client";

export const GET_BUYERS_LIST = gql`
  query ($input: GetListDTO!) {
    listAllBuyers(input: $input) {
      data {
        _id
        firstName
        lastName
        phoneNumber
        email
        status
      }
      pagination {
        total
        hasNextPage
      }
    }
  }
`;

export const GET_BUYER_PROFILE = gql`
  query ($input: BuyerDetailInput!) {
    getBuyerDetail(input: $input) {
      data {
        _id
        firstName
        lastName
        phoneNumber
        email
        displayImage
      }
    }
  }
`;
