import { gql } from "@apollo/client";

export const GET_GLOBAL_SETTING = gql`
  query {
    getGlobalSubscriptionSetting {
      _id
      monthlyFee
      residentialPricing {
        salePost
        rentPost
      }
      commercialPricing {
        salePost
        rentPost
      }
      relistingPricing {
        salePost
        rentPost
        holidayLettings
      }
    }
  }
`;
