import { HeadCell } from "types";

export const defaultSubscription = {
  _id: "",
  agencyOwner: "",
  startDate: "",
  plan: "",
  agencyName: "",
  status: "",
};

export const headCells: HeadCell[] = [
  {
    id: "agencyName",
    numeric: false,
    label: "Agency Name",
    align: "left",
  },
  {
    id: "agencyOwner",
    numeric: false,
    label: "Agency Admin",
    align: "left",
  },
  {
    id: "startDate",
    numeric: false,
    label: "Date commenced",
    align: "left",
  },
  {
    id: "plan",
    numeric: false,
    label: "Subscription Plan",
    align: "left",
  },

  {
    id: "status",
    numeric: false,
    label: "Status ",
    align: "right",
  },
];
