// assets
import { IconBuildingBank, IconChartPie2 } from "@tabler/icons";

import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import CachedIcon from "@mui/icons-material/Cached";

//REALSTATE ICONS
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import WebIcon from "@mui/icons-material/Web";
import EmailIcon from "@mui/icons-material/Email";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import PropertiesIcon from "components/icons/AllProperties";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import StarIcon from "@mui/icons-material/Star";

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
  items: [
    {
      id: "dashboard",
      type: "group",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          icon: IconChartPie2,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "userManagement",
      type: "group",
      title: "User Management",
      children: [
        {
          id: "admin-list",
          title: "Admins",
          type: "item",
          url: "/admin-list",
          icon: HowToRegOutlinedIcon,
          breadcrumbs: true,
          hasSuperAdminAccess: true,
        },
        {
          id: "agencies",
          title: "Agencies",
          type: "item",
          url: "/agencies",
          icon: IconBuildingBank,
          breadcrumbs: true,
        },
        {
          id: "agents",
          title: "Agents",
          type: "item",
          url: "/agents",
          icon: PeopleOutlineOutlinedIcon,
          breadcrumbs: true,
        },
        {
          id: "app-users",
          title: "App",
          type: "item",
          url: "/app-users",
          icon: GroupsOutlinedIcon,
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "management",
      type: "group",
      title: "Management",
      children: [
        {
          id: "pages",
          title: "Pages",
          type: "item",
          url: "/pages",
          icon: WebIcon,
          breadcrumbs: true,
        },
        {
          id: "subscription",
          title: "Subscription",
          type: "item",
          icon: CachedIcon,
          url: "/subscription",
          breadcrumbs: true,
          hasFinanacialAccess: true,
        },
        {
          id: "email-template-list",
          title: "Email Template",
          type: "item",
          url: "/email-template-list",
          icon: EmailIcon,
          breadcrumbs: true,
        },
        {
          id: "review-settings",
          title: "Review Settings",
          type: "item",
          icon: StarIcon,
          url: "/review-settings",
          breadcrumbs: true,
        },
        {
          id: "report",
          title: "Report Forms",
          type: "item",
          icon: ReportGmailerrorredIcon,
          url: "/report",
          breadcrumbs: true,
        },
        {
          id: "contactus",
          title: "Contact Us Forms",
          type: "item",
          url: "/contactus",
          icon: ArticleOutlinedIcon,
          breadcrumbs: true,
        },
        {
          id: "properties",
          title: "All Properties",
          type: "item",
          url: "/properties",
          breadcrumbs: true,
          icon: PropertiesIcon,
        },

        {
          id: "settings",
          title: "Settings",
          type: "item",
          url: "/settings",
          breadcrumbs: true,
          icon: SettingsIcon,
        },
      ],
    },
  ],
};

export default MenuItems;
