import { useNavigate } from "react-router-dom";

// project imports
import { useEffect } from "react";
import { GuardProps } from "types";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const isValidToken: string = localStorage.getItem("refreshToken") || "";
  const isLoggedIn: string = localStorage.getItem("isLoggedIn") || "";

  useEffect(() => {
    if (!isLoggedIn && !isValidToken) {
      navigate("/login", { replace: true });
    }
  }, [navigate, isLoggedIn, isValidToken]);

  return children;
};

export default AuthGuard;
