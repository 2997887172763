import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import { useDispatch } from "react-redux";
import { setLoginState } from "store/slices/auth";
import useGQL from "hooks/useGQL";
import ThumbNailImageComponent from "components/image/ThumbNail";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { GET_ADMIN_PROFILE } = useGQL();

  const { data } = GET_ADMIN_PROFILE();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const { LOGOUT_AS_AN_ADMIN } = useGQL();

  const [logOutAsAnAdmin] = LOGOUT_AS_AN_ADMIN();

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);
  const handleLogout = async () => {
    await logOutAsAnAdmin();
    /* cleanup auth state */
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isLoggedIn");

    dispatch(setLoginState({ isLoggedIn: false }));
    navigate("/login", { replace: true });
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    route: string = ""
  ) => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (
    event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
  ) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "52px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          paddingRight: "4px",
          borderColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[500],
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.main
              : theme.palette.grey[100],
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.grey[100],
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <ThumbNailImageComponent
            awsKey={data?.getUserProfile?.displayImage}
            size="84x84"
          >
            <Avatar
              sx={{
                ...theme.typography.mediumAvatar,
                margin: "8px 0 8px 8px !important",
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          </ThumbNailImageComponent>
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.secondary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 25px 50px rgba(0, 0, 0, 0.25)",
          },
          "& .MuiCard-root": {
            padding: "24px 16px",
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    content={false}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Stack direction="column" spacing={2}>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Good Morning,</Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            Admin
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2">
                          Project Admin
                        </Typography>
                      </Box>

                      <List
                        component="nav"
                        sx={{
                          minWidth: "268px",

                          [theme.breakpoints.down("md")]: {},
                          "& .MuiListItemButton-root": {},
                        }}
                      >
                        <ListItemButton
                          color="secondary"
                          sx={{ borderRadius: "12px" }}
                          selected={selectedIndex === 0}
                          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                            handleListItemClick(event, 0, "/admin/profile")
                          }
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Profile</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                      <Divider />
                      <Box sx={{ pt: 1 }}>
                        <Button
                          onClick={handleLogout}
                          startIcon={<IconLogout stroke={1.5} />}
                          variant="text"
                          disableRipple
                          disableFocusRipple
                          sx={{
                            padding: "0 20px",
                            width: "auto",
                            justifyContent: "flex-start",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          Logout
                        </Button>
                      </Box>
                    </Stack>
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
