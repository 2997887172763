// routing
import Routes from "routes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import ThemeCustomization from "themes";
import Locales from "ui-component/Locales";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* RTL layout */}
    <Locales>
      <NavigationScroll>
        <AuthProvider>
          <>
            <Routes />
            {/* <Snackbar /> */}
          </>
        </AuthProvider>
      </NavigationScroll>
    </Locales>
  </ThemeCustomization>
);

export default App;
