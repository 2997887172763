/* eslint no-underscore-dangle: 0 */
import {
  Avatar,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
} from "@mui/material";

import { NotificationsForAdmin } from "../constants/types";

import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import useSnackbar from "hooks/common/useSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import { headCells } from "../constants/variables";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { updateReadStatus } from "grapqhl/adminNotification/query";
import ThumbNailImageComponent from "components/image/ThumbNail";

type NotificationTableProps = {
  notifications: NotificationsForAdmin[];
  handleEdit: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
};

const NotificationTable = ({
  notifications,
  handleEdit,
  handleRefetch,
  setIsSelected,
  selected,
  setSelected,
}: NotificationTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const { handleOpenSnackbar } = useSnackbar();

  const [updateStatus] = useLazyQuery(updateReadStatus);

  const handleSeenProperty = async (id: string | undefined) => {
    try {
      await updateStatus({ variables: { updateNotificationId: id } });
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
    }
  };

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };
  const isSelected = (notification: string) =>
    selected.indexOf(notification) !== -1;

  return (
    <>
      <Grid
        container
        sx={{ py: 2 }}
        rowSpacing={2}
        columnSpacing={{ sx: 1, md: 2 }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ mt: "12px", mb: "23px" }}>
            Notifications
          </Typography>
        </Grid>
      </Grid>

      <TableContainer sx={{ px: "16px" }}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                if (headCell.id === "action") {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={headCell.align}
                      //padding={headCell.disablePadding ? "none" : "normal"}
                      sx={{
                        py: "5px!important",
                        px: "13px",
                        borderBottom: "1px solid #cdced1",
                      }}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    //padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{
                      py: "5px!important",
                      px: "13px",
                      borderBottom: "1px solid #cdced1",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody sx={{ color: "black" }}>
            {notifications.length !== 0 &&
              notifications.map((notification, index) => {
                if (typeof notification === "number") return null;

                const isItemSelected = isSelected(notification._id);
                return (
                  <TableRow
                    hover
                    key={notification._id}
                    role="checkbox"
                    tabIndex={-1}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      background: notification?.isRead ? "white" : "#fbe9e7",
                      borderBottom: "1px solid #cdced1",
                    }}
                  >
                    <TableCell sx={{ py: "14px", px: "13px" }}>
                      {notification?.agentOrAgencyPhoto ? (
                        <ThumbNailImageComponent
                          awsKey={notification?.agentOrAgencyPhoto}
                          size="84x84"
                        >
                          <Avatar sx={{ width: "32px", height: "32px" }} />
                        </ThumbNailImageComponent>
                      ) : (
                        <Avatar sx={{ width: "32px", height: "32px" }} />
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ py: "14px", px: "13px" }}>
                      {notification?.agentOrAgencyName}
                    </TableCell>
                    <TableCell align="left" sx={{ py: "14px", px: "13px" }}>
                      <Link
                        href={`/properties/${notification?.propertyId}`}
                        underline="none"
                        color="#323232"
                        onClick={() => {
                          handleSeenProperty(notification?._id);
                        }}
                      >
                        {notification?.activityText === "Property added" &&
                        notification?.propertyTitle
                          ? `Created a new ${notification?.propertyTitle}`
                          : "Created a new property"}
                      </Link>
                    </TableCell>
                    <TableCell align="left" sx={{ py: "14px", px: "13px" }}>
                      {moment(notification?.date).format("DD/MM/YYYY  h:mm a")}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default NotificationTable;
