import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Subscriptions from "views/subscription";
import FinancialAccessGuard from "utils/route-guard/FinancialAccesGuard";
import NotificationList from "views/NotificationList";
import SuperAdminGuard from "utils/route-guard/SuperAdminGuard";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));

// CMS pages routing
const CmsPagesLists = Loadable(lazy(() => import("views/cms-pages")));
const EditCmsPages = Loadable(
  lazy(() => import("views/cms-pages/edit-cms-pages"))
);

// report listing
const ReportListing = Loadable(lazy(() => import("views/report")));
const ReportDetails = Loadable(
  lazy(() => import("views/report/reportDetails"))
);

// review settings
const ReviewSettingsPage = Loadable(
  lazy(() => import("views/review-settings"))
);

// contactus
const ContactUsListing = Loadable(lazy(() => import("views/contact-us")));
const ContactUsDetails = Loadable(
  lazy(() => import("views/contact-us/contactusDetails"))
);

//admins
const AddNewAdmin = Loadable(
  lazy(() => import("views/userManagement/admins/add-new-admin"))
);

const EditAdmin = Loadable(
  lazy(() => import("views/userManagement/admins/edit-admin"))
);
//buyers
const BuyersList = Loadable(lazy(() => import("views/userManagement/buyers")));
const AddNewBuyer = Loadable(
  lazy(() => import("views/userManagement/buyers/add-new-buyer"))
);
const EditBuyer = Loadable(
  lazy(() => import("views/userManagement/buyers/edit-existing-buyer"))
);

//agents
const AgentsList = Loadable(lazy(() => import("views/userManagement/agents")));
const CreateNewAgent = Loadable(
  lazy(() => import("views/userManagement/agents/add-new-agent"))
);
const ViewAgentDetails = Loadable(
  lazy(() => import("views/userManagement/agents/view-agent"))
);
const EditAgentDetails = Loadable(
  lazy(
    () =>
      import(
        "views/userManagement/agents/view-agent/edit-agent/edit-personal-details"
      )
  )
);

//agency loading
const AgenciesList = Loadable(
  lazy(() => import("views/userManagement/agencies"))
);
const CreateNewAgency = Loadable(
  lazy(() => import("views/userManagement/agencies/add-new-agency"))
);

const AgencyProfile = Loadable(
  lazy(() => import("views/userManagement/agencies/view-agency/agency-profile"))
);

const EditAgencyProfile = Loadable(
  lazy(
    () =>
      import(
        "views/userManagement/agencies/view-agency/agency-profile/edit-agency-profile/index"
      )
  )
);

// menu routing
const MenuList = Loadable(lazy(() => import("views/menu/menusList")));
const AddMenu = Loadable(lazy(() => import("views/menu/addMenu")));
const EditMenu = Loadable(lazy(() => import("views/menu/editMenu")));

// page management routing
const PageList = Loadable(lazy(() => import("views/pageManagement")));
const AdvancedPageList = Loadable(
  lazy(() => import("views/pageManagement/advancedPage"))
);
const AddPage = Loadable(
  lazy(() => import("views/pageManagement/forms/addPage"))
);
const EditPage = Loadable(
  lazy(() => import("views/pageManagement/advancedPageEdit"))
);

// menu routing
const TaxonomyList = Loadable(
  lazy(() => import("views/taxonomy/taxonomyList"))
);
const ViewTaxonomy = Loadable(lazy(() => import("views/taxonomy/taxonsList")));
const AddTaxon = Loadable(lazy(() => import("views/taxonomy/addTaxonForm")));
const EditTaxon = Loadable(lazy(() => import("views/taxonomy/editTaxonForm")));

// User managemenet routing
const AdminList = Loadable(lazy(() => import("views/userManagement/admins")));
const AdminAdd = Loadable(
  lazy(() => import("views/userManagement/admins/forms/add-admin"))
);
const AdminProfile = Loadable(
  lazy(() => import("views/userManagement/admins/profile"))
);
const AdminAccountProfile = Loadable(
  lazy(() => import("views/userManagement/admins/profile/user-profile"))
);
const AdminChangePassword = Loadable(
  lazy(() => import("views/userManagement/admins/profile/change-password"))
);
const UserList = Loadable(lazy(() => import("views/userManagement/users")));
const UserProfile = Loadable(
  lazy(() => import("views/userManagement/users/profile"))
);
const AppUserList = Loadable(
  lazy(() => import("views/userManagement/appUsers"))
);
const AppUserProfile = Loadable(
  lazy(() => import("views/userManagement/appUsers/profile"))
);
const AddAppUser = Loadable(
  lazy(() => import("views/userManagement/appUsers/forms/AddAppUser"))
);
const EditAppUser = Loadable(
  lazy(() => import("views/userManagement/appUsers/forms/EditAppUser"))
);

/* faq */
const FAQList = Loadable(lazy(() => import("views/FAQ")));
const AddFAQV1 = Loadable(lazy(() => import("views/FAQ/forms/addForm/v1")));
const EditFAQV1 = Loadable(
  lazy(() => import("views/FAQ/forms/editForm/v1/EditFAQ"))
);
const ManageFAQ = Loadable(lazy(() => import("views/FAQ/tables")));

/* testimonials */
const TestimonialsList = Loadable(lazy(() => import("views/testimonials")));
const AddTestimonials = Loadable(
  lazy(() => import("views/testimonials/forms/AddTestimonials"))
);
const EditTestimonials = Loadable(
  lazy(() => import("views/testimonials/forms/EditTestimonials"))
);

/* email template */
const EmailTemplateList = Loadable(lazy(() => import("views/emailTemplate")));
const AddEmailTemplate = Loadable(
  lazy(() => import("views/emailTemplate/forms/addTemplate"))
);
const EditEmailTemplate = Loadable(
  lazy(() => import("views/emailTemplate/forms/editTemplate"))
);

/*All Properties*/
const AllPropertiesList = Loadable(
  lazy(() => import("views/all-properties/index"))
);

/*Settings*/
const Settings = Loadable(lazy(() => import("views/settings/index")));

// Mock component
const MockComponent = Loadable(lazy(() => import("views/mock")));

// Error routing
const ErrorPage = Loadable(lazy(() => import("views/pages/maintenance/error")));

const PropertyDetails = Loadable(
  lazy(() => import("views/all-properties/propertyDetails/index"))
);

const ViewSubscription = Loadable(
  lazy(() => import("views/subscription/manage-subscription/index"))
);

const ViewInvoiceDetail = Loadable(
  lazy(
    () =>
      import(
        "views/subscription/manage-subscription/billing-history/billing-history-detail/index"
      )
  )
);

const CancelSubsription = Loadable(
  lazy(
    () =>
      import("views/subscription/manage-subscription/cancel-subscription/index")
  )
);

const GlobalSubscriptionSetting = Loadable(
  lazy(() => import("views/subscription/global-setting/index"))
);

const EditSubscriptionSetting = Loadable(
  lazy(
    () =>
      import(
        "views/subscription/manage-subscription/subscription-detail/EditSubscriptionSetting"
      )
  )
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },

    {
      path: "menu/list",
      element: <MenuList />,
    },
    {
      path: "menu/add",
      element: <AddMenu />,
    },
    {
      path: "menu/edit/:id",
      element: <EditMenu />,
    },

    {
      path: "taxonomy/list",
      element: <TaxonomyList />,
    },
    {
      path: "taxonomy/view/:taxonomyId",
      element: <ViewTaxonomy />,
    },
    {
      path: "taxon/add/:taxonomyId",
      element: <AddTaxon />,
    },
    {
      path: "taxon/edit/:taxonomyId/:id",
      element: <EditTaxon />,
    },

    {
      path: "page-management/add",
      element: <AddPage />,
    },
    {
      path: "page-management/edit/:id",
      element: <EditPage />,
    },
    {
      path: "page-management/list",
      element: <PageList />,
    },
    {
      path: "page-management/add/advance",
      element: <AdvancedPageList />,
    },
    {
      path: "user/list",
      element: <UserList />,
    },
    {
      path: "user/profile/:id",
      element: <UserProfile />,
    },
    {
      path: "app-user/list",
      element: <AppUserList />,
    },
    {
      path: "app-user/add",
      element: <AddAppUser />,
    },
    {
      path: "app-user/edit/:id",
      element: <EditAppUser />,
    },
    {
      path: "app-user/profile/:id",
      element: <AppUserProfile />,
    },
    {
      path: "admin-list",
      element: (
        <SuperAdminGuard>
          <AdminList />
        </SuperAdminGuard>
      ),
    },
    {
      path: "admin/add",
      element: (
        <SuperAdminGuard>
          <AdminAdd />,
        </SuperAdminGuard>
      ),
    },
    {
      path: "admin/profile",
      element: <AdminProfile />,
    },
    {
      path: "admin/account-profile",
      element: <AdminAccountProfile />,
    },
    {
      path: "admin/change-password",
      element: <AdminChangePassword />,
    },
    {
      path: "faq/list",
      element: <FAQList />,
    },
    {
      path: "faq/add",
      element: <AddFAQV1 />,
    },
    {
      path: "faq/edit/:id",
      element: <EditFAQV1 />,
    },
    {
      path: "faq/manage-faq",
      element: <ManageFAQ />,
    },
    {
      path: "email-template-list",
      element: <EmailTemplateList />,
    },
    {
      path: "email-template/add",
      element: <AddEmailTemplate />,
    },
    {
      path: "email-template/edit/:id",
      element: <EditEmailTemplate />,
    },
    {
      path: "/testimonials/list",
      element: <TestimonialsList />,
    },
    {
      path: "testimonials/add",
      element: <AddTestimonials />,
    },
    {
      path: "testimonials/edit/:id",
      element: <EditTestimonials />,
    },
    {
      path: "mock",
      element: <MockComponent />,
    },
    {
      path: "/agencies",
      element: <AgenciesList />,
    },
    {
      path: "/agencies/add-new-agency",
      element: <CreateNewAgency />,
    },
    {
      path: "/agencies/profile/:id",
      element: <AgencyProfile />,
    },
    {
      path: "/agencies/profile/edit/:id",
      element: <EditAgencyProfile />,
    },
    {
      path: "/agents",
      element: <AgentsList />,
    },
    {
      path: "/agents/add-new-agent",
      element: <CreateNewAgent />,
    },
    {
      path: "/agents/view-agent/:id",
      element: <ViewAgentDetails />,
    },
    {
      path: "/agents/edit-agent/details/:id",
      element: <EditAgentDetails />,
    },
    {
      path: "/agents/edit-agent/account-settings/:id",
      element: <ViewAgentDetails />,
    },
    {
      path: "/app-users",
      element: <BuyersList />,
    },
    {
      path: "/app-users/add-new-app-user",
      element: <AddNewBuyer />,
    },
    {
      path: "/app-users/edit/:id",
      element: <EditBuyer />,
    },
    {
      path: "/admin/add-new-admin",
      element: <AddNewAdmin />,
    },
    {
      path: "/admin/edit/:id",
      element: <EditAdmin />,
    },
    {
      path: "/contactus",
      element: <ContactUsListing />,
    },
    {
      path: "/contactus/details/:id",
      element: <ContactUsDetails />,
    },
    {
      path: "/subscription",
      element: (
        <FinancialAccessGuard>
          <Subscriptions />
        </FinancialAccessGuard>
      ),
    },
    {
      path: "/subscription/global-setting",
      element: (
        <SuperAdminGuard>
          <GlobalSubscriptionSetting />
        </SuperAdminGuard>
      ),
    },
    {
      path: "/review-settings",
      element: <ReviewSettingsPage />,
    },
    {
      path: "/pages",
      element: <CmsPagesLists />,
    },
    {
      path: "/pages/:slug",
      element: <EditCmsPages />,
    },
    {
      path: "/report",
      element: <ReportListing />,
    },
    {
      path: "/report/details/:id",
      element: <ReportDetails />,
    },
    {
      path: "/properties",
      element: <AllPropertiesList />,
    },
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "properties/:id",
      element: <PropertyDetails />,
    },
    {
      path: "/notifications",
      element: <NotificationList />,
    },
    {
      path: "/subscription/:id",
      element: (
        <FinancialAccessGuard>
          <ViewSubscription />
        </FinancialAccessGuard>
      ),
    },
    {
      path: "/subscription/billing/:id",
      element: (
        <FinancialAccessGuard>
          <ViewInvoiceDetail />
        </FinancialAccessGuard>
      ),
    },
    {
      path: "/subscription/cancel",
      element: (
        <FinancialAccessGuard>
          <CancelSubsription />
        </FinancialAccessGuard>
      ),
    },
    {
      path: "/edit-subscription-setting/:id",
      element: <EditSubscriptionSetting />,
    },
  ],
};

export default MainRoutes;
